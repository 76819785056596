export let softs = [
 {
  "id": "1",
  "ini": "ひ",
  "name": "ビックリマンワールド",
  "maker": "ハドソン",
  "releaseDate": "1987/10/30",
  "price": "4500",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "2",
  "ini": "し",
  "name": "上海",
  "maker": "ハドソン",
  "releaseDate": "1987/10/30",
  "price": "4500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "3",
  "ini": "さ",
  "name": "THE 功夫",
  "maker": "ハドソン",
  "releaseDate": "1987/11/21",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "4",
  "ini": "か",
  "name": "カトちゃんケンちゃん",
  "maker": "ハドソン",
  "releaseDate": "1987/11/30",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "5",
  "ini": "ひ",
  "name": "ビクトリーラン 栄光の13,000キロ",
  "maker": "ハドソン",
  "releaseDate": "1987/12/28",
  "price": "4500",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "6",
  "ini": "し",
  "name": "邪聖剣ネクロマンサー",
  "maker": "ハドソン",
  "releaseDate": "1988/1/22",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "7",
  "ini": "よ",
  "name": "妖怪道中記",
  "maker": "ナムコ",
  "releaseDate": "1988/2/5",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "8",
  "ini": "あ",
  "name": "R-TYPE Ⅰ",
  "maker": "ハドソン",
  "releaseDate": "1988/3/25",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "9",
  "ini": "ゆ",
  "name": "遊々人生",
  "maker": "ハドソン",
  "releaseDate": "1988/4/22",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "10",
  "ini": "ふ",
  "name": "プロ野球 ワールドスタジアム",
  "maker": "ナムコ",
  "releaseDate": "1988/5/20",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "11",
  "ini": "あ",
  "name": "R-TYPE Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1988/6/3",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "12",
  "ini": "は",
  "name": "パワーリーグ",
  "maker": "ハドソン",
  "releaseDate": "1988/6/24",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "13",
  "ini": "せ",
  "name": "戦国麻雀",
  "maker": "ハドソン",
  "releaseDate": "1988/7/8",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "14",
  "ini": "き",
  "name": "ギャラガ'88",
  "maker": "ナムコ",
  "releaseDate": "1988/7/15",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "15",
  "ini": "ふ",
  "name": "プロテニス ワールドコート",
  "maker": "ナムコ",
  "releaseDate": "1988/8/11",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "16",
  "ini": "ま",
  "name": "魔神英雄伝ワタル",
  "maker": "ハドソン",
  "releaseDate": "1988/8/30",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "17",
  "ini": "え",
  "name": "エイリアンクラッシュ",
  "maker": "ナグザット",
  "releaseDate": "1988/9/14",
  "price": "5500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "18",
  "ini": "か",
  "name": "ガイアの紋章",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1988/9/23",
  "price": "5500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "19",
  "ini": "ま",
  "name": "魔境伝説",
  "maker": "ビクター音楽産業",
  "releaseDate": "1988/9/23",
  "price": "5200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "20",
  "ini": "ふ",
  "name": "ファンタジーゾーン",
  "maker": "NECアベニュー",
  "releaseDate": "1988/10/14",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "21",
  "ini": "さ",
  "name": "定吉七番",
  "maker": "ハドソン",
  "releaseDate": "1988/11/18",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "22",
  "ini": "の",
  "name": "No・Ri・Ko",
  "maker": "ハドソン",
  "releaseDate": "1988/12/4",
  "price": "4980",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "23",
  "ini": "ふ",
  "name": "ファイティング・ストリート",
  "maker": "ハドソン",
  "releaseDate": "1988/12/4",
  "price": "5980",
  "genre": "対戦格闘",
  "icNo": "4"
 },
 {
  "id": "24",
  "ini": "す",
  "name": "スペースハリアー",
  "maker": "NECアベニュー",
  "releaseDate": "1988/12/9",
  "price": "6700",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "25",
  "ini": "と",
  "name": "ドラゴンスピリット",
  "maker": "ナムコ",
  "releaseDate": "1988/12/16",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "26",
  "ini": "あ",
  "name": "あっぱれゲートボール",
  "maker": "ハドソン",
  "releaseDate": "1988/12/22",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "27",
  "ini": "ひ",
  "name": "ビックリマン大事界",
  "maker": "ハドソン",
  "releaseDate": "1988/12/23",
  "price": "4980",
  "genre": "その他・クイズ",
  "icNo": "4"
 },
 {
  "id": "28",
  "ini": "ひ",
  "name": "ビジランテ",
  "maker": "アイレム",
  "releaseDate": "1989/1/14",
  "price": "6300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "29",
  "ini": "そ",
  "name": "ソンソンⅡ",
  "maker": "NECアベニュー",
  "releaseDate": "1989/1/27",
  "price": "5400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "30",
  "ini": "ね",
  "name": "ネクタリス",
  "maker": "ハドソン",
  "releaseDate": "1989/2/9",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "31",
  "ini": "も",
  "name": "モトローダー",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1989/2/23",
  "price": "5200",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "32",
  "ini": "は",
  "name": "はにい いんざ すかい",
  "maker": "フェイス",
  "releaseDate": "1989/3/1",
  "price": "5200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "33",
  "ini": "う",
  "name": "ウイニングショット",
  "maker": "データイースト",
  "releaseDate": "1989/3/3",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "34",
  "ini": "た",
  "name": "ダンジョン エクスプローラー",
  "maker": "ハドソン",
  "releaseDate": "1989/3/4",
  "price": "5800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "35",
  "ini": "あ",
  "name": "アウトライブ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1989/3/17",
  "price": "5600",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "36",
  "ini": "か",
  "name": "改造町人シュビビンマン",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1989/3/18",
  "price": "5200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "37",
  "ini": "ひ",
  "name": "P-47 THE FREEDOM FIGHTER",
  "maker": "エイコム",
  "releaseDate": "1989/3/20",
  "price": "5200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "38",
  "ini": "え",
  "name": "F-1 PILOT YOU'RE KING OF KINGS",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1989/3/23",
  "price": "6300",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "39",
  "ini": "し",
  "name": "死霊戦線 WAR OF THE DEAD",
  "maker": "ビクター音楽産業",
  "releaseDate": "1989/3/24",
  "price": "5500",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "40",
  "ini": "か",
  "name": "がんばれ！ゴルフボーイズ",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1989/3/28",
  "price": "5300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "41",
  "ini": "す",
  "name": "SPACE ADVENTURE コブラ 黒竜王の伝説",
  "maker": "ハドソン",
  "releaseDate": "1989/3/31",
  "price": "5980",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "42",
  "ini": "て",
  "name": "ディープブルー 海底神話",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1989/3/31",
  "price": "5300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "43",
  "ini": "き",
  "name": "究極タイガー",
  "maker": "タイトー",
  "releaseDate": "1989/3/31",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "44",
  "ini": "ま",
  "name": "魔界八犬伝 SHADA",
  "maker": "データイースト",
  "releaseDate": "1989/4/1",
  "price": "5800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "45",
  "ini": "え",
  "name": "エナジー",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1989/4/19",
  "price": "5200",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "46",
  "ini": "わ",
  "name": "ワンダーモモ",
  "maker": "ナムコ",
  "releaseDate": "1989/4/21",
  "price": "5200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "47",
  "ini": "す",
  "name": "凄ノ王伝説",
  "maker": "ハドソン",
  "releaseDate": "1989/4/27",
  "price": "6500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "48",
  "ini": "は",
  "name": "パワーゴルフ",
  "maker": "ハドソン",
  "releaseDate": "1989/5/25",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "49",
  "ini": "な",
  "name": "ナグザットオープン",
  "maker": "ナグザット",
  "releaseDate": "1989/5/30",
  "price": "6300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "50",
  "ini": "は",
  "name": "パックランド",
  "maker": "ナムコ",
  "releaseDate": "1989/6/1",
  "price": "5200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "51",
  "ini": "わ",
  "name": "わいわい麻雀 ゆかいな雀友たち",
  "maker": "ビデオシステム",
  "releaseDate": "1989/6/19",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "52",
  "ini": "ふ",
  "name": "ファイアープロレスリング コンビネーションタッグ",
  "maker": "ヒューマン",
  "releaseDate": "1989/6/22",
  "price": "6300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "53",
  "ini": "さ",
  "name": "サイバークロス",
  "maker": "フェイス",
  "releaseDate": "1989/6/23",
  "price": "6300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "54",
  "ini": "う",
  "name": "ヴァリスⅡ",
  "maker": "日本テレネット",
  "releaseDate": "1989/6/23",
  "price": "6780",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "55",
  "ini": "し",
  "name": "神武伝承",
  "maker": "ビッグ・クラブ",
  "releaseDate": "1989/6/28",
  "price": "6700",
  "genre": "アクション・3D",
  "icNo": "1"
 },
 {
  "id": "56",
  "ini": "に",
  "name": "ニンジャウォーリアーズ",
  "maker": "タイトー",
  "releaseDate": "1989/6/30",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "57",
  "ini": "て",
  "name": "天外魔境 ZIRIA",
  "maker": "ハドソン",
  "releaseDate": "1989/6/30",
  "price": "7200",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "58",
  "ini": "か",
  "name": "ガンヘッド",
  "maker": "ハドソン",
  "releaseDate": "1989/7/7",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "59",
  "ini": "ふ",
  "name": "ファイナルラップ・ツイン",
  "maker": "ナムコ",
  "releaseDate": "1989/7/7",
  "price": "6200",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "60",
  "ini": "さ",
  "name": "サイドアーム",
  "maker": "NECアベニュー",
  "releaseDate": "1989/7/14",
  "price": "5400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "61",
  "ini": "た",
  "name": "武田信玄",
  "maker": "エイコム",
  "releaseDate": "1989/7/28",
  "price": "5600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "62",
  "ini": "め",
  "name": "めぞん一刻",
  "maker": "マイクロキャビン",
  "releaseDate": "1989/8/4",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "63",
  "ini": "は",
  "name": "パワーリーグⅡ",
  "maker": "ハドソン",
  "releaseDate": "1989/8/8",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "64",
  "ini": "ふ",
  "name": "ブレイク・イン",
  "maker": "ナグザット",
  "releaseDate": "1989/8/10",
  "price": "5500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "65",
  "ini": "え",
  "name": "F-1 DREAM",
  "maker": "NECアベニュー",
  "releaseDate": "1989/8/25",
  "price": "5400",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "66",
  "ini": "ろ",
  "name": "ROCK・ON",
  "maker": "ビッグ・クラブ",
  "releaseDate": "1989/8/25",
  "price": "6700",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "67",
  "ini": "わ",
  "name": "ワンダーボーイⅢ モンスター・レアー",
  "maker": "ハドソン",
  "releaseDate": "1989/8/31",
  "price": "5800",
  "genre": "アクション・シューティング",
  "icNo": "4"
 },
 {
  "id": "68",
  "ini": "な",
  "name": "ならずもの戦闘部隊 BLOODY WOLF",
  "maker": "データイースト",
  "releaseDate": "1989/9/1",
  "price": "6500",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "69",
  "ini": "お",
  "name": "オーダイン",
  "maker": "ナムコ",
  "releaseDate": "1989/9/8",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "70",
  "ini": "す",
  "name": "SUPER アルバトロス",
  "maker": "日本テレネット",
  "releaseDate": "1989/9/14",
  "price": "6780",
  "genre": "スポーツ",
  "icNo": "4"
 },
 {
  "id": "71",
  "ini": "す",
  "name": "スーパー桃太郎電鉄",
  "maker": "ハドソン",
  "releaseDate": "1989/9/15",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "72",
  "ini": "し",
  "name": "獣王記",
  "maker": "NECアベニュー",
  "releaseDate": "1989/9/22",
  "price": "5800",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "73",
  "ini": "し",
  "name": "獣王記",
  "maker": "NECアベニュー",
  "releaseDate": "1989/9/29",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "74",
  "ini": "あ",
  "name": "アーティストツール",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1989/9/29",
  "price": "5800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "75",
  "ini": "た",
  "name": "ダブルダンジョン",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1989/9/29",
  "price": "5500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "76",
  "ini": "て",
  "name": "DIGITAL CHAMP バトルボクシング",
  "maker": "ナグザット",
  "releaseDate": "1989/10/13",
  "price": "5800",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "77",
  "ini": "た",
  "name": "竜の子ファイター",
  "maker": "トンキンハウス",
  "releaseDate": "1989/10/20",
  "price": "5600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "78",
  "ini": "か",
  "name": "鏡の国のレジェンド",
  "maker": "ビクター音楽産業",
  "releaseDate": "1989/10/27",
  "price": "6750",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "79",
  "ini": "ろ",
  "name": "ロムロムカラオケ vol.1",
  "maker": "NECアベニュー",
  "releaseDate": "1989/10/27",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "80",
  "ini": "ろ",
  "name": "ロムロムカラオケ vol.2",
  "maker": "NECアベニュー",
  "releaseDate": "1989/10/27",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "81",
  "ini": "と",
  "name": "ドラえもん 迷宮大作戦",
  "maker": "ハドソン",
  "releaseDate": "1989/10/31",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "82",
  "ini": "つ",
  "name": "都留照人の実戦株式倍バイゲーム",
  "maker": "インテック",
  "releaseDate": "1989/11/1",
  "price": "9800",
  "genre": "株・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "83",
  "ini": "に",
  "name": "ニュートピア",
  "maker": "ハドソン",
  "releaseDate": "1989/11/17",
  "price": "5800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "84",
  "ini": "た",
  "name": "大地くんクライシス",
  "maker": "サリオ",
  "releaseDate": "1989/11/22",
  "price": "6400",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "85",
  "ini": "ま",
  "name": "麻雀学園 東間宗四郎登場",
  "maker": "フェイス",
  "releaseDate": "1989/11/24",
  "price": "7980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "86",
  "ini": "し",
  "name": "ジャック・ニクラウス チャンピオンシップ・ゴルフ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1989/11/24",
  "price": "6200",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "87",
  "ini": "き",
  "name": "ぎゅわんぶらあ自己中心派 CDだよ全員集合!! 激闘36雀士",
  "maker": "ハドソン",
  "releaseDate": "1989/11/24",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "4"
 },
 {
  "id": "88",
  "ini": "は",
  "name": "バリバリ伝説",
  "maker": "タイトー",
  "releaseDate": "1989/11/29",
  "price": "6600",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "89",
  "ini": "み",
  "name": "Mr.HELIの大冒険",
  "maker": "アイレム",
  "releaseDate": "1989/12/1",
  "price": "6700",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "90",
  "ini": "ゆ",
  "name": "USAプロバスケットボール",
  "maker": "エイコム",
  "releaseDate": "1989/12/1",
  "price": "5600",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "91",
  "ini": "ふ",
  "name": "ブルファイト リングの覇者",
  "maker": "クリーム",
  "releaseDate": "1989/12/8",
  "price": "6300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "92",
  "ini": "し",
  "name": "忍 SHINOBI",
  "maker": "アスミック",
  "releaseDate": "1989/12/8",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "93",
  "ini": "は",
  "name": "バトルエース",
  "maker": "ハドソン",
  "releaseDate": "1989/12/8",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "2"
 },
 {
  "id": "94",
  "ini": "ひ",
  "name": "PC原人",
  "maker": "ハドソン",
  "releaseDate": "1989/12/15",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "95",
  "ini": "さ",
  "name": "サイドアーム・スペシャル",
  "maker": "NECアベニュー",
  "releaseDate": "1989/12/15",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "96",
  "ini": "こ",
  "name": "これがプロ野球'89",
  "maker": "インテック",
  "releaseDate": "1989/12/20",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "97",
  "ini": "ろ",
  "name": "ロムロムカラオケ vol.3",
  "maker": "NECアベニュー",
  "releaseDate": "1989/12/20",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "98",
  "ini": "い",
  "name": "イースⅠ・Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1989/12/21",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "4"
 },
 {
  "id": "99",
  "ini": "な",
  "name": "ナイトライダー スペシャル",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1989/12/22",
  "price": "5800",
  "genre": "レース・シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "100",
  "ini": "へ",
  "name": "ヘビーユニット",
  "maker": "タイトー",
  "releaseDate": "1989/12/22",
  "price": "6600",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "101",
  "ini": "へ",
  "name": "弁慶外伝",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1989/12/22",
  "price": "6200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "102",
  "ini": "ろ",
  "name": "ROMROM スタジアム",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1989/12/22",
  "price": "6200",
  "genre": "スポーツ",
  "icNo": "4"
 },
 {
  "id": "103",
  "ini": "え",
  "name": "F1トリプルバトル",
  "maker": "ヒューマン",
  "releaseDate": "1989/12/23",
  "price": "6300",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "104",
  "ini": "う",
  "name": "ヴォルフィード",
  "maker": "タイトー",
  "releaseDate": "1989/12/27",
  "price": "6600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "105",
  "ini": "れ",
  "name": "レッドアラート",
  "maker": "日本テレネット",
  "releaseDate": "1989/12/28",
  "price": "6780",
  "genre": "アクション・シューティング",
  "icNo": "4"
 },
 {
  "id": "106",
  "ini": "あ",
  "name": "アトミックロボキッド",
  "maker": "UPL",
  "releaseDate": "1990/1/19",
  "price": "6700",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "107",
  "ini": "ろ",
  "name": "ロムロムカラオケ vol.4",
  "maker": "NECアベニュー",
  "releaseDate": "1990/1/19",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "108",
  "ini": "か",
  "name": "ガイフレーム",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/1/26",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "109",
  "ini": "た",
  "name": "タイトー チェイスH.Q.",
  "maker": "タイトー",
  "releaseDate": "1990/1/26",
  "price": "6600",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "110",
  "ini": "ち",
  "name": "逐電屋 藤兵衛",
  "maker": "ナグザット",
  "releaseDate": "1990/1/26",
  "price": "6700",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "111",
  "ini": "ま",
  "name": "麻雀刺客列伝 麻雀ウォーズ",
  "maker": "日本物産",
  "releaseDate": "1990/2/1",
  "price": "5400",
  "genre": "テーブル・RPG",
  "icNo": "1"
 },
 {
  "id": "112",
  "ini": "す",
  "name": "スーパーバレーボール",
  "maker": "ビデオシステム",
  "releaseDate": "1990/2/7",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "113",
  "ini": "に",
  "name": "ニュージーランドストーリー",
  "maker": "タイトー",
  "releaseDate": "1990/2/23",
  "price": "6600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "114",
  "ini": "ふ",
  "name": "ブロディア",
  "maker": "ハドソン",
  "releaseDate": "1990/2/23",
  "price": "4500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "115",
  "ini": "と",
  "name": "虎への道",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/2/23",
  "price": "6700",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "116",
  "ini": "ひ",
  "name": "飛装騎兵カイザード",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/2/23",
  "price": "6200",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "117",
  "ini": "に",
  "name": "西村京太郎ミステリー 北斗星の女",
  "maker": "ナグザット",
  "releaseDate": "1990/2/23",
  "price": "6300",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "118",
  "ini": "は",
  "name": "パラノイア",
  "maker": "ナグザット",
  "releaseDate": "1990/3/1",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "119",
  "ini": "し",
  "name": "シティハンター",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/3/2",
  "price": "6300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "120",
  "ini": "す",
  "name": "スペースインベーダーズ 復活の日",
  "maker": "タイトー",
  "releaseDate": "1990/3/2",
  "price": "5900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "121",
  "ini": "な",
  "name": "謎のマスカレード",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/3/2",
  "price": "6000",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "122",
  "ini": "さ",
  "name": "サイバーコア",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1990/3/9",
  "price": "6700",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "123",
  "ini": "こ",
  "name": "ゴールデンアックス",
  "maker": "日本テレネット",
  "releaseDate": "1990/3/10",
  "price": "6780",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "124",
  "ini": "け",
  "name": "源平討魔伝",
  "maker": "ナムコ",
  "releaseDate": "1990/3/16",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "125",
  "ini": "そ",
  "name": "倉庫番ワールド",
  "maker": "メディアリング",
  "releaseDate": "1990/3/16",
  "price": "5400",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "126",
  "ini": "す",
  "name": "スーパーダライアス",
  "maker": "NECアベニュー",
  "releaseDate": "1990/3/16",
  "price": "6780",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "127",
  "ini": "あ",
  "name": "アームドF",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/3/23",
  "price": "6700",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "128",
  "ini": "ふ",
  "name": "ファイナルゾーンⅡ",
  "maker": "日本テレネット",
  "releaseDate": "1990/3/23",
  "price": "6780",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "129",
  "ini": "き",
  "name": "奇々怪界",
  "maker": "タイトー",
  "releaseDate": "1990/3/27",
  "price": "5500",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "130",
  "ini": "ひ",
  "name": "BE BALL",
  "maker": "ハドソン",
  "releaseDate": "1990/3/30",
  "price": "5200",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "131",
  "ini": "き",
  "name": "キング オブ カジノ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/3/30",
  "price": "6200",
  "genre": "テーブル・スロット",
  "icNo": "1"
 },
 {
  "id": "132",
  "ini": "と",
  "name": "ドロップロック ほらホラ",
  "maker": "データイースト",
  "releaseDate": "1990/3/30",
  "price": "5900",
  "genre": "ブロック崩し",
  "icNo": "1"
 },
 {
  "id": "133",
  "ini": "ね",
  "name": "熱血高校ドッジボール部 PC番外編",
  "maker": "ナグザット",
  "releaseDate": "1990/3/30",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "134",
  "ini": "か",
  "name": "カルメン・サンディエゴを追え！世界編",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/3/30",
  "price": "7200",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "135",
  "ini": "こ",
  "name": "コズミック・ファンタジー 冒険少年ユウ",
  "maker": "日本テレネット",
  "releaseDate": "1990/3/30",
  "price": "6780",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "136",
  "ini": "ろ",
  "name": "ROM2カラオケ VOL1 すてきにスタンダード",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/3/30",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "137",
  "ini": "ろ",
  "name": "ROM2カラオケ VOL2 なっとくアイドル",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/3/30",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "138",
  "ini": "す",
  "name": "スプラッターハウス",
  "maker": "ナムコ",
  "releaseDate": "1990/4/3",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "139",
  "ini": "さ",
  "name": "サイコチェイサー",
  "maker": "ナグザット",
  "releaseDate": "1990/4/6",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "140",
  "ini": "ま",
  "name": "魔動王グランゾート",
  "maker": "ハドソン",
  "releaseDate": "1990/4/6",
  "price": "6500",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "141",
  "ini": "ろ",
  "name": "ROM2カラオケ VOL3 やっぱしバンド",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/4/6",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "142",
  "ini": "ろ",
  "name": "ROM2カラオケ VOL4 ちょいとおとな？",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/4/6",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "143",
  "ini": "ろ",
  "name": "ROM2カラオケ VOL5 カラオケ幕の内",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/4/6",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "144",
  "ini": "は",
  "name": "パワードリフト",
  "maker": "アスミック",
  "releaseDate": "1990/4/13",
  "price": "6900",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "145",
  "ini": "し",
  "name": "上海Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1990/4/13",
  "price": "5800",
  "genre": "パズル",
  "icNo": "4"
 },
 {
  "id": "146",
  "ini": "ね",
  "name": "ネクロスの要塞",
  "maker": "アスク講談社",
  "releaseDate": "1990/4/20",
  "price": "6800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "147",
  "ini": "ろ",
  "name": "ロムロムカラオケ vol.5",
  "maker": "NECアベニュー",
  "releaseDate": "1990/4/23",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "148",
  "ini": "は",
  "name": "バルンバ",
  "maker": "ナムコ",
  "releaseDate": "1990/4/27",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "149",
  "ini": "ふ",
  "name": "フォーメーションサッカー ヒューマンカップ'90",
  "maker": "ヒューマン",
  "releaseDate": "1990/4/27",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "150",
  "ini": "あ",
  "name": "青いブリンク",
  "maker": "NHKエンタープライズ、ハドソン",
  "releaseDate": "1990/4/27",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "151",
  "ini": "す",
  "name": "スーパー大戦略",
  "maker": "マイクロキャビン",
  "releaseDate": "1990/4/27",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "152",
  "ini": "て",
  "name": "デスブリンガー THE KNIGHT OF DARKNESS",
  "maker": "日本テレネット",
  "releaseDate": "1990/4/27",
  "price": "7200",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "153",
  "ini": "ま",
  "name": "マニアックプロレス 明日への闘い",
  "maker": "ハドソン",
  "releaseDate": "1990/5/25",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "154",
  "ini": "と",
  "name": "ドンドコドン",
  "maker": "タイトー",
  "releaseDate": "1990/5/31",
  "price": "6600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "155",
  "ini": "し",
  "name": "シンドバッド 地底の大魔宮",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1990/6/2",
  "price": "6700",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "156",
  "ini": "う",
  "name": "ヴェイグス",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/6/15",
  "price": "6700",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "157",
  "ini": "し",
  "name": "CD-ROMマガジン ウルトラボックス創刊号",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/6/15",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "158",
  "ini": "た",
  "name": "ダウンロード",
  "maker": "NECアベニュー",
  "releaseDate": "1990/6/22",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "159",
  "ini": "こ",
  "name": "これがプロ野球'90",
  "maker": "インテック",
  "releaseDate": "1990/6/29",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "160",
  "ini": "せ",
  "name": "ゼビウス ファードラウト伝説",
  "maker": "ナムコ",
  "releaseDate": "1990/6/29",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "161",
  "ini": "は",
  "name": "パズニック",
  "maker": "タイトー",
  "releaseDate": "1990/6/29",
  "price": "5900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "162",
  "ini": "ま",
  "name": "麻雀学園MILD 東間宗四郎登場",
  "maker": "フェイス",
  "releaseDate": "1990/6/29",
  "price": "7980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "163",
  "ini": "う",
  "name": "うる星やつら STAY WITH YOU",
  "maker": "ハドソン",
  "releaseDate": "1990/6/29",
  "price": "6500",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "164",
  "ini": "そ",
  "name": "ソル・ビアンカ",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/6/29",
  "price": "6200",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "165",
  "ini": "す",
  "name": "スーパースターソルジャー",
  "maker": "ハドソン",
  "releaseDate": "1990/7/6",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "166",
  "ini": "ら",
  "name": "ラスタン・サーガⅡ",
  "maker": "タイトー",
  "releaseDate": "1990/7/6",
  "price": "6600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "167",
  "ini": "さ",
  "name": "最後の忍道 NINJA SPIRIT",
  "maker": "アイレム",
  "releaseDate": "1990/7/6",
  "price": "7000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "168",
  "ini": "め",
  "name": "迷宮のエルフィーネ",
  "maker": "日本テレネット",
  "releaseDate": "1990/7/6",
  "price": "6780",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "169",
  "ini": "ち",
  "name": "超絶倫人ベラボーマン",
  "maker": "ナムコ",
  "releaseDate": "1990/7/13",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "170",
  "ini": "て",
  "name": "デビルクラッシュ",
  "maker": "ナグザット",
  "releaseDate": "1990/7/20",
  "price": "6300",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "171",
  "ini": "も",
  "name": "桃太郎伝説ターボ",
  "maker": "ハドソン",
  "releaseDate": "1990/7/20",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "172",
  "ini": "い",
  "name": "イメージファイト",
  "maker": "アイレム",
  "releaseDate": "1990/7/27",
  "price": "7000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "173",
  "ini": "ろ",
  "name": "ロードランナー 失われた迷宮",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/7/27",
  "price": "5800",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "174",
  "ini": "わ",
  "name": "ワールドビーチバレー",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1990/7/27",
  "price": "5700",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "175",
  "ini": "た",
  "name": "大魔界村",
  "maker": "NECアベニュー",
  "releaseDate": "1990/7/27",
  "price": "10800",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "176",
  "ini": "ち",
  "name": "地獄めぐり",
  "maker": "タイトー",
  "releaseDate": "1990/8/3",
  "price": "6600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "177",
  "ini": "わ",
  "name": "ワルキューレの伝説",
  "maker": "ナムコ",
  "releaseDate": "1990/8/9",
  "price": "6800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "178",
  "ini": "く",
  "name": "クラックス",
  "maker": "テンゲン",
  "releaseDate": "1990/8/10",
  "price": "5900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "179",
  "ini": "は",
  "name": "パワーリーグⅢ",
  "maker": "ハドソン",
  "releaseDate": "1990/8/10",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "180",
  "ini": "し",
  "name": "将棋 初段一直線",
  "maker": "ホームデータ",
  "releaseDate": "1990/8/10",
  "price": "6700",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "181",
  "ini": "ま",
  "name": "麻雀悟空 スペシャル",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/8/10",
  "price": "6300",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "182",
  "ini": "ま",
  "name": "マジカルサウルスツアー 最新恐竜図解大辞典",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/8/24",
  "price": "8700",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "183",
  "ini": "お",
  "name": "オペレーション・ウルフ",
  "maker": "NECアベニュー",
  "releaseDate": "1990/8/31",
  "price": "7200",
  "genre": "ガンシューティング",
  "icNo": "1"
 },
 {
  "id": "184",
  "ini": "ら",
  "name": "ラスト・ハルマゲドン",
  "maker": "ブレイン・グレイ",
  "releaseDate": "1990/8/31",
  "price": "7500",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "185",
  "ini": "は",
  "name": "はにい おんざ ろおど",
  "maker": "フェイス",
  "releaseDate": "1990/9/7",
  "price": "6400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "186",
  "ini": "あ",
  "name": "暗黒伝説",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/9/7",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "187",
  "ini": "う",
  "name": "ヴァリスⅢ",
  "maker": "日本テレネット",
  "releaseDate": "1990/9/7",
  "price": "6780",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "188",
  "ini": "え",
  "name": "F1サーカス",
  "maker": "日本物産",
  "releaseDate": "1990/9/14",
  "price": "6900",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "189",
  "ini": "せ",
  "name": "関ヶ原",
  "maker": "トンキンハウス",
  "releaseDate": "1990/9/14",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "190",
  "ini": "し",
  "name": "ジャック・ニクラウス ワールドゴルフツアー 162ホール",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/9/14",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "4"
 },
 {
  "id": "191",
  "ini": "み",
  "name": "みつばち学園",
  "maker": "ハドソン",
  "releaseDate": "1990/9/14",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "192",
  "ini": "た",
  "name": "ダライアス・プラス",
  "maker": "NECアベニュー",
  "releaseDate": "1990/9/21",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "193",
  "ini": "も",
  "name": "桃太郎活劇",
  "maker": "ハドソン",
  "releaseDate": "1990/9/21",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "194",
  "ini": "れ",
  "name": "レギオン",
  "maker": "日本テレネット",
  "releaseDate": "1990/9/21",
  "price": "6780",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "195",
  "ini": "あ",
  "name": "アフターバーナーⅡ",
  "maker": "NECアベニュー",
  "releaseDate": "1990/9/28",
  "price": "7200",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "196",
  "ini": "こ",
  "name": "ゴモラスピード",
  "maker": "ユーピーエル",
  "releaseDate": "1990/9/28",
  "price": "6700",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "197",
  "ini": "し",
  "name": "CD-ROMマガジン ウルトラボックス2号",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/9/28",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "198",
  "ini": "た",
  "name": "ダイハード",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/9/28",
  "price": "7200",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "199",
  "ini": "た",
  "name": "ダブルリング",
  "maker": "ナグザット",
  "releaseDate": "1990/9/28",
  "price": "6300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "200",
  "ini": "ふ",
  "name": "ファイナルブラスター",
  "maker": "ナムコ",
  "releaseDate": "1990/9/28",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "201",
  "ini": "さ",
  "name": "ザ・プロ野球",
  "maker": "インテック",
  "releaseDate": "1990/10/5",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "4"
 },
 {
  "id": "202",
  "ini": "し",
  "name": "雀偵物語",
  "maker": "日本テレネット",
  "releaseDate": "1990/10/9",
  "price": "6780",
  "genre": "テーブル",
  "icNo": "4"
 },
 {
  "id": "203",
  "ini": "は",
  "name": "BATMAN",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/10/12",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "204",
  "ini": "さ",
  "name": "サイバーナイト",
  "maker": "トンキンハウス",
  "releaseDate": "1990/10/12",
  "price": "6800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "205",
  "ini": "ら",
  "name": "ラビオレプス スペシャル",
  "maker": "ビデオシステム",
  "releaseDate": "1990/10/19",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "206",
  "ini": "な",
  "name": "ナグザットスタジアム",
  "maker": "ナグザット",
  "releaseDate": "1990/10/26",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "207",
  "ini": "り",
  "name": "琉球",
  "maker": "フェイス",
  "releaseDate": "1990/10/26",
  "price": "5400",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "208",
  "ini": "え",
  "name": "エアロブラスターズ",
  "maker": "ハドソン",
  "releaseDate": "1990/11/2",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "209",
  "ini": "て",
  "name": "デコボコ伝説 走るワガマンマー",
  "maker": "日本テレネット",
  "releaseDate": "1990/11/2",
  "price": "6780",
  "genre": "レース",
  "icNo": "4"
 },
 {
  "id": "210",
  "ini": "か",
  "name": "カットビ！宅配くん",
  "maker": "トンキンハウス",
  "releaseDate": "1990/11/9",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "211",
  "ini": "き",
  "name": "キックボール",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/11/23",
  "price": "6200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "212",
  "ini": "し",
  "name": "J.B.HAROLD SERIES #1 Murder Club",
  "maker": "ハドソン",
  "releaseDate": "1990/11/23",
  "price": "6500",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "213",
  "ini": "さ",
  "name": "サンダーブレード",
  "maker": "NECアベニュー",
  "releaseDate": "1990/12/7",
  "price": "7200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "214",
  "ini": "は",
  "name": "バーニングエンジェル",
  "maker": "ナグザット",
  "releaseDate": "1990/12/7",
  "price": "6700",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "215",
  "ini": "ほ",
  "name": "ボンバーマン",
  "maker": "ハドソン",
  "releaseDate": "1990/12/7",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "216",
  "ini": "あ",
  "name": "アヴェンジャー",
  "maker": "日本テレネット",
  "releaseDate": "1990/12/7",
  "price": "6780",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "217",
  "ini": "ら",
  "name": "らんま1/2",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/12/7",
  "price": "6800",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "218",
  "ini": "ふ",
  "name": "不思議の夢のアリス",
  "maker": "フェイス",
  "releaseDate": "1990/12/7",
  "price": "6400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "219",
  "ini": "め",
  "name": "メルヘンメイズ",
  "maker": "ナムコ",
  "releaseDate": "1990/12/11",
  "price": "5500",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "220",
  "ini": "し",
  "name": "ZIPANG",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/12/14",
  "price": "6200",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "221",
  "ini": "す",
  "name": "スピンペア",
  "maker": "メディアリング",
  "releaseDate": "1990/12/14",
  "price": "5900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "222",
  "ini": "ち",
  "name": "チャンピオン・レスラー",
  "maker": "タイトー",
  "releaseDate": "1990/12/14",
  "price": "6600",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "223",
  "ini": "と",
  "name": "トイ・ショップ・ボーイズ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/12/14",
  "price": "6200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "224",
  "ini": "は",
  "name": "バイオレント・ソルジャー",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1990/12/14",
  "price": "6400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "225",
  "ini": "わ",
  "name": "ワラビー",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/12/14",
  "price": "6500",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "226",
  "ini": "た",
  "name": "大旋風",
  "maker": "NECアベニュー",
  "releaseDate": "1990/12/14",
  "price": "7200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "227",
  "ini": "は",
  "name": "バスティール",
  "maker": "ヒューマン",
  "releaseDate": "1990/12/20",
  "price": "6500",
  "genre": "シミュレーション・アクション",
  "icNo": "4"
 },
 {
  "id": "228",
  "ini": "あ",
  "name": "アウトラン",
  "maker": "NECアベニュー",
  "releaseDate": "1990/12/21",
  "price": "7200",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "229",
  "ini": "く",
  "name": "クロスワイバー CYBER-COMBAT-POLICE",
  "maker": "フェイス",
  "releaseDate": "1990/12/21",
  "price": "6700",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "230",
  "ini": "て",
  "name": "天聖龍",
  "maker": "エイコム",
  "releaseDate": "1990/12/21",
  "price": "7200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "231",
  "ini": "ま",
  "name": "魔界プリンス どらぼっちゃん",
  "maker": "ナグザット",
  "releaseDate": "1990/12/21",
  "price": "6700",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "232",
  "ini": "も",
  "name": "桃太郎伝説Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1990/12/22",
  "price": "7200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "233",
  "ini": "し",
  "name": "CD-ROMマガジン ウルトラボックス3号",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/12/28",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "234",
  "ini": "お",
  "name": "オーバーライド",
  "maker": "データイースト",
  "releaseDate": "1991/1/8",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "235",
  "ini": "か",
  "name": "カダッシュ",
  "maker": "タイトー",
  "releaseDate": "1991/1/18",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "236",
  "ini": "し",
  "name": "ジャッキーチェン",
  "maker": "ハドソン",
  "releaseDate": "1991/1/18",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "237",
  "ini": "え",
  "name": "S.C.I.",
  "maker": "タイトー",
  "releaseDate": "1991/1/25",
  "price": "7200",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "238",
  "ini": "か",
  "name": "ガルクライト TDF2",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1991/1/25",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "239",
  "ini": "は",
  "name": "パラソルスター",
  "maker": "タイトー",
  "releaseDate": "1991/2/15",
  "price": "6600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "240",
  "ini": "く",
  "name": "クイズアベニュー",
  "maker": "NECアベニュー",
  "releaseDate": "1991/2/15",
  "price": "5800",
  "genre": "クイズ",
  "icNo": "4"
 },
 {
  "id": "241",
  "ini": "ま",
  "name": "マスターオブモンスターズ",
  "maker": "マイクロキャビン",
  "releaseDate": "1991/2/15",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "242",
  "ini": "お",
  "name": "オルディネス",
  "maker": "ハドソン",
  "releaseDate": "1991/2/22",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "2"
 },
 {
  "id": "243",
  "ini": "は",
  "name": "パズルボーイ",
  "maker": "日本テレネット",
  "releaseDate": "1991/2/22",
  "price": "6400",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "244",
  "ini": "て",
  "name": "デッドムーン 月世界の悪夢",
  "maker": "ティー・エス・エス",
  "releaseDate": "1991/2/28",
  "price": "7400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "245",
  "ini": "ふ",
  "name": "ファイナルマッチテニス",
  "maker": "ヒューマン",
  "releaseDate": "1991/3/1",
  "price": "5700",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "246",
  "ini": "せ",
  "name": "ゼロヨンチャンプ",
  "maker": "メディアリング",
  "releaseDate": "1991/3/8",
  "price": "6900",
  "genre": "レース・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "247",
  "ini": "れ",
  "name": "レジェンド・オブ・ヒーロー・トンマ",
  "maker": "アイレム",
  "releaseDate": "1991/3/13",
  "price": "7000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "248",
  "ini": "お",
  "name": "おぼっちゃまくん",
  "maker": "ナムコ",
  "releaseDate": "1991/3/15",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "249",
  "ini": "た",
  "name": "タイタン",
  "maker": "ナグザット",
  "releaseDate": "1991/3/15",
  "price": "5900",
  "genre": "ブロック崩し",
  "icNo": "1"
 },
 {
  "id": "250",
  "ini": "し",
  "name": "CYBER CITY OEDO 808 獣の属性",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1991/3/15",
  "price": "6500",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "251",
  "ini": "ふ",
  "name": "プロ野球 ワールドスタジアム'91",
  "maker": "ナムコ",
  "releaseDate": "1991/3/21",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "252",
  "ini": "い",
  "name": "1943 改",
  "maker": "ナグザット",
  "releaseDate": "1991/3/22",
  "price": "7200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "253",
  "ini": "い",
  "name": "イースⅢ",
  "maker": "ハドソン",
  "releaseDate": "1991/3/22",
  "price": "7200",
  "genre": "アクション・RPG",
  "icNo": "4"
 },
 {
  "id": "254",
  "ini": "ま",
  "name": "マンホール",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1991/3/22",
  "price": "6500",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "255",
  "ini": "ろ",
  "name": "ロード・スピリッツ",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1991/3/22",
  "price": "7200",
  "genre": "レース・3D",
  "icNo": "4"
 },
 {
  "id": "256",
  "ini": "ま",
  "name": "魔晶伝紀ラ・ヴァルー",
  "maker": "工画堂スタジオ",
  "releaseDate": "1991/3/22",
  "price": "7200",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "257",
  "ini": "て",
  "name": "TVスポーツ・フットボール",
  "maker": "ビクター音楽産業",
  "releaseDate": "1991/3/29",
  "price": "6700",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "258",
  "ini": "こ",
  "name": "コラムス",
  "maker": "日本テレネット",
  "releaseDate": "1991/3/29",
  "price": "6400",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "259",
  "ini": "さ",
  "name": "サイレントデバッガーズ",
  "maker": "データイースト",
  "releaseDate": "1991/3/29",
  "price": "6800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "260",
  "ini": "も",
  "name": "モトローダーⅡ",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1991/3/29",
  "price": "6500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "261",
  "ini": "え",
  "name": "エグザイル 時の狭間へ",
  "maker": "日本テレネット",
  "releaseDate": "1991/3/29",
  "price": "6780",
  "genre": "アクション・RPG",
  "icNo": "4"
 },
 {
  "id": "262",
  "ini": "た",
  "name": "ダウンロード2",
  "maker": "NECアベニュー",
  "releaseDate": "1991/3/29",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "263",
  "ini": "さ",
  "name": "三国志 英傑天下に臨む",
  "maker": "ナグザット",
  "releaseDate": "1991/3/29",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "264",
  "ini": "ほ",
  "name": "ポピュラス",
  "maker": "ハドソン",
  "releaseDate": "1991/4/5",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "265",
  "ini": "え",
  "name": "エルディス",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1991/4/5",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "266",
  "ini": "く",
  "name": "クイズ まるごと The ワールド",
  "maker": "アトラス",
  "releaseDate": "1991/4/5",
  "price": "6200",
  "genre": "クイズ",
  "icNo": "4"
 },
 {
  "id": "267",
  "ini": "こ",
  "name": "コズミックファンタジー2 冒険少年バン",
  "maker": "日本テレネット",
  "releaseDate": "1991/4/5",
  "price": "6780",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "268",
  "ini": "さ",
  "name": "サーカスライド",
  "maker": "ユニ・ポスト",
  "releaseDate": "1991/4/6",
  "price": "5400",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "269",
  "ini": "え",
  "name": "エターナルシティ 都市転送計画",
  "maker": "ナグザット",
  "releaseDate": "1991/4/12",
  "price": "6900",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "270",
  "ini": "は",
  "name": "ハイグレネーダー",
  "maker": "日本テレネット",
  "releaseDate": "1991/4/12",
  "price": "6780",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "271",
  "ini": "へ",
  "name": "ヘルファイアーS",
  "maker": "NECアベニュー",
  "releaseDate": "1991/4/12",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "272",
  "ini": "あ",
  "name": "アドベンチャーアイランド",
  "maker": "ハドソン",
  "releaseDate": "1991/4/19",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "273",
  "ini": "は",
  "name": "パチ夫くん 幻の伝説",
  "maker": "ココナッツ・ジャパン",
  "releaseDate": "1991/4/19",
  "price": "12800",
  "genre": "パチンコ",
  "icNo": "4"
 },
 {
  "id": "274",
  "ini": "か",
  "name": "改造町人シュビビンマン2 新たなる敵",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1991/4/27",
  "price": "6600",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "275",
  "ini": "し",
  "name": "CD-ROMマガジン ウルトラボックス4号",
  "maker": "ビクター音楽産業",
  "releaseDate": "1991/5/24",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "276",
  "ini": "は",
  "name": "ハットリス",
  "maker": "マイクロキャビン",
  "releaseDate": "1991/5/24",
  "price": "5800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "277",
  "ini": "ほ",
  "name": "ポンピングワールド",
  "maker": "ハドソン",
  "releaseDate": "1991/5/31",
  "price": "4500",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "278",
  "ini": "す",
  "name": "SPACE ADVENTURE コブラⅡ伝説の男",
  "maker": "ハドソン",
  "releaseDate": "1991/6/7",
  "price": "6500",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "279",
  "ini": "ら",
  "name": "ライザンバーⅡ",
  "maker": "データウエスト",
  "releaseDate": "1991/6/7",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "280",
  "ini": "は",
  "name": "パワーイレブン",
  "maker": "ハドソン",
  "releaseDate": "1991/6/21",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "281",
  "ini": "ま",
  "name": "魔笛伝説アストラリウス",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1991/6/21",
  "price": "6300",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "282",
  "ini": "す",
  "name": "スプラッシュレイク",
  "maker": "NECアベニュー",
  "releaseDate": "1991/6/28",
  "price": "5800",
  "genre": "アクション・パズル",
  "icNo": "4"
 },
 {
  "id": "283",
  "ini": "せ",
  "name": "戦国関東三国志",
  "maker": "インテック",
  "releaseDate": "1991/6/28",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "284",
  "ini": "ふ",
  "name": "ファイナルソルジャー",
  "maker": "ハドソン",
  "releaseDate": "1991/7/5",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "285",
  "ini": "と",
  "name": "トリッキー",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1991/7/6",
  "price": "6700",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "286",
  "ini": "え",
  "name": "F1サーカス'91",
  "maker": "日本物産",
  "releaseDate": "1991/7/12",
  "price": "6900",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "287",
  "ini": "せ",
  "name": "精霊戦士スプリガン",
  "maker": "ナグザット",
  "releaseDate": "1991/7/12",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "288",
  "ini": "ひ",
  "name": "PC原人2",
  "maker": "ハドソン",
  "releaseDate": "1991/7/19",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "289",
  "ini": "れ",
  "name": "レーシング魂",
  "maker": "アイレム",
  "releaseDate": "1991/7/19",
  "price": "7000",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "290",
  "ini": "め",
  "name": "メタルストーカー",
  "maker": "フェイス",
  "releaseDate": "1991/7/21",
  "price": "6980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "291",
  "ini": "し",
  "name": "シャーロック・ホームズの探偵講座",
  "maker": "ビクター音楽産業",
  "releaseDate": "1991/7/26",
  "price": "7200",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "292",
  "ini": "た",
  "name": "大旋風カスタム",
  "maker": "NECアベニュー",
  "releaseDate": "1991/7/26",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "293",
  "ini": "す",
  "name": "スクウィーク",
  "maker": "ビクター音楽産業",
  "releaseDate": "1991/8/2",
  "price": "6400",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "294",
  "ini": "ふ",
  "name": "ブライ 八玉の勇士伝説",
  "maker": "リバーヒルソフト",
  "releaseDate": "1991/8/9",
  "price": "7200",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "295",
  "ini": "は",
  "name": "はなたーかだか?",
  "maker": "タイトー",
  "releaseDate": "1991/8/9",
  "price": "7200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "296",
  "ini": "は",
  "name": "パワーリーグ4",
  "maker": "ハドソン",
  "releaseDate": "1991/8/9",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "297",
  "ini": "い",
  "name": "1941 カウンターアタック",
  "maker": "ハドソン",
  "releaseDate": "1991/8/23",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "2"
 },
 {
  "id": "298",
  "ini": "う",
  "name": "ヴァリスⅣ",
  "maker": "日本テレネット",
  "releaseDate": "1991/8/23",
  "price": "6780",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "299",
  "ini": "は",
  "name": "パワーゲイト",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1991/8/30",
  "price": "6200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "300",
  "ini": "ふ",
  "name": "ファイアープロレスリング 2nd BOUT",
  "maker": "ヒューマン",
  "releaseDate": "1991/8/30",
  "price": "6900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "301",
  "ini": "せ",
  "name": "聖竜伝説モンビット",
  "maker": "ハドソン",
  "releaseDate": "1991/8/30",
  "price": "6800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "302",
  "ini": "ひ",
  "name": "ヒット ジ アイス",
  "maker": "タイトー",
  "releaseDate": "1991/9/20",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "303",
  "ini": "わ",
  "name": "ワールドジョッキー",
  "maker": "ナムコ",
  "releaseDate": "1991/9/20",
  "price": "5800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "304",
  "ini": "し",
  "name": "CD-ROMマガジン ウルトラボックス5号",
  "maker": "ビクター音楽産業",
  "releaseDate": "1991/9/27",
  "price": "4800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "305",
  "ini": "と",
  "name": "どらごんEGG！",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1991/9/27",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "306",
  "ini": "に",
  "name": "ニュートピアⅡ",
  "maker": "ハドソン",
  "releaseDate": "1991/9/27",
  "price": "7200",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "307",
  "ini": "も",
  "name": "森田将棋PC",
  "maker": "NECアベニュー",
  "releaseDate": "1991/9/27",
  "price": "7200",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "308",
  "ini": "め",
  "name": "メソポタミア",
  "maker": "アトラス",
  "releaseDate": "1991/10/4",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "309",
  "ini": "く",
  "name": "クイズアベニューⅡ",
  "maker": "NECアベニュー",
  "releaseDate": "1991/10/11",
  "price": "6000",
  "genre": "クイズ",
  "icNo": "4"
 },
 {
  "id": "310",
  "ini": "わ",
  "name": "ワールドサーキット",
  "maker": "ナムコ",
  "releaseDate": "1991/10/18",
  "price": "5800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "311",
  "ini": "と",
  "name": "ドラゴンスレイヤー 英雄伝説",
  "maker": "ハドソン",
  "releaseDate": "1991/10/25",
  "price": "6800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "312",
  "ini": "は",
  "name": "まーじゃん バニラシンドローム",
  "maker": "日本物産",
  "releaseDate": "1991/10/25",
  "price": "6900",
  "genre": "テーブル",
  "icNo": "4"
 },
 {
  "id": "313",
  "ini": "ほ",
  "name": "ポピュラス ザ・プロミストランド",
  "maker": "ハドソン",
  "releaseDate": "1991/10/25",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "314",
  "ini": "て",
  "name": "天使の詩",
  "maker": "日本テレネット",
  "releaseDate": "1991/10/25",
  "price": "7400",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "315",
  "ini": "た",
  "name": "タイムクルーズⅡ",
  "maker": "フェイス",
  "releaseDate": "1991/11/8",
  "price": "7200",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "316",
  "ini": "ふ",
  "name": "プリンス・オブ・ペルシャ",
  "maker": "リバーヒルソフト",
  "releaseDate": "1991/11/8",
  "price": "6800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "317",
  "ini": "く",
  "name": "グラディウス",
  "maker": "コナミ",
  "releaseDate": "1991/11/15",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "318",
  "ini": "ま",
  "name": "マジカルチェイス",
  "maker": "パルソフト",
  "releaseDate": "1991/11/15",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "319",
  "ini": "ら",
  "name": "雷電",
  "maker": "ハドソン",
  "releaseDate": "1991/11/22",
  "price": "7200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "320",
  "ini": "こ",
  "name": "コリューン",
  "maker": "ナグザット",
  "releaseDate": "1991/11/29",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "321",
  "ini": "す",
  "name": "スーパーメタルクラッシャー",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1991/11/29",
  "price": "6200",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "322",
  "ini": "ふ",
  "name": "ファイティングラン",
  "maker": "日本物産",
  "releaseDate": "1991/11/29",
  "price": "6900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "323",
  "ini": "も",
  "name": "モンスタープロレス",
  "maker": "アスク講談社",
  "releaseDate": "1991/11/29",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "324",
  "ini": "し",
  "name": "将棋 初心者無用",
  "maker": "ホームデータ",
  "releaseDate": "1991/11/29",
  "price": "7300",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "325",
  "ini": "れ",
  "name": "レディファントム",
  "maker": "日本テレネット",
  "releaseDate": "1991/11/29",
  "price": "6980",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "326",
  "ini": "ろ",
  "name": "ロードオブウォーズ",
  "maker": "システムソフト",
  "releaseDate": "1991/11/29",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "327",
  "ini": "と",
  "name": "ドラえもん のび太のドラビアンナイト",
  "maker": "ハドソン",
  "releaseDate": "1991/12/6",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "328",
  "ini": "は",
  "name": "バブルガムクラッシュ！",
  "maker": "ナグザット",
  "releaseDate": "1991/12/6",
  "price": "7200",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "329",
  "ini": "さ",
  "name": "沙羅曼蛇",
  "maker": "コナミ",
  "releaseDate": "1991/12/6",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "330",
  "ini": "す",
  "name": "スーパーシュヴァルツシルト",
  "maker": "工画堂スタジオ",
  "releaseDate": "1991/12/6",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "331",
  "ini": "ら",
  "name": "らんま1/2 とらわれの花嫁",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1991/12/6",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "332",
  "ini": "け",
  "name": "ゲンジ通信あげだま",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1991/12/13",
  "price": "5800",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "333",
  "ini": "す",
  "name": "スパイラルウェーブ",
  "maker": "メディアリング",
  "releaseDate": "1991/12/13",
  "price": "6900",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "334",
  "ini": "え",
  "name": "NHKおかあさんといっしょ にこにこ、ぷん",
  "maker": "NHKエンタープライズ",
  "releaseDate": "1991/12/13",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "335",
  "ini": "は",
  "name": "バリスティックス",
  "maker": "ココナッツ・ジャパン",
  "releaseDate": "1991/12/13",
  "price": "6900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "336",
  "ini": "え",
  "name": "エフェラ アンド ジリオラ ジ・エンブレム フロム ダークネス",
  "maker": "ブレイングレイ",
  "releaseDate": "1991/12/13",
  "price": "7200",
  "genre": "アクション・RPG",
  "icNo": "4"
 },
 {
  "id": "337",
  "ini": "す",
  "name": "SUPER CD・ROM2 体験ソフト集",
  "maker": "ハドソン",
  "releaseDate": "1991/12/13",
  "price": "1000",
  "genre": "その他",
  "icNo": "5"
 },
 {
  "id": "338",
  "ini": "た",
  "name": "太平記",
  "maker": "インテック",
  "releaseDate": "1991/12/13",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "339",
  "ini": "ひ",
  "name": "秘宝伝説 クリスの冒険",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1991/12/13",
  "price": "6800",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "340",
  "ini": "す",
  "name": "スーパー桃太郎電鉄Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1991/12/20",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "341",
  "ini": "あ",
  "name": "R-TYPE COMPLETE CD",
  "maker": "アイレム",
  "releaseDate": "1991/12/20",
  "price": "7500",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "342",
  "ini": "な",
  "name": "なりトレ ザ・スゴロク'92",
  "maker": "日本テレネット",
  "releaseDate": "1991/12/20",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "4"
 },
 {
  "id": "343",
  "ini": "ね",
  "name": "熱血高校ドッジボール部 CDサッカー編",
  "maker": "ナグザット",
  "releaseDate": "1991/12/20",
  "price": "7200",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "344",
  "ini": "と",
  "name": "ドラゴンセイバー",
  "maker": "ナムコ",
  "releaseDate": "1991/12/27",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "345",
  "ini": "さ",
  "name": "斬 陽炎の時代",
  "maker": "タイトー",
  "releaseDate": "1991/12/27",
  "price": "7500",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "346",
  "ini": "ほ",
  "name": "冒険男爵・ドン THE LOST SUNHEART",
  "maker": "アイマックス",
  "releaseDate": "1992/1/4",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "347",
  "ini": "ち",
  "name": "ちびまる子ちゃん クイズでピーヒャラ",
  "maker": "ナムコ",
  "releaseDate": "1992/1/10",
  "price": "6800",
  "genre": "クイズ・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "348",
  "ini": "み",
  "name": "ミズバク大冒険",
  "maker": "タイトー",
  "releaseDate": "1992/1/17",
  "price": "7400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "349",
  "ini": "に",
  "name": "忍者龍剣伝",
  "maker": "ハドソン",
  "releaseDate": "1992/1/24",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "350",
  "ini": "ま",
  "name": "Might and Magic",
  "maker": "NECアベニュー",
  "releaseDate": "1992/1/24",
  "price": "7200",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "351",
  "ini": "え",
  "name": "NHK大河ドラマ 太平記",
  "maker": "NHKエンタープライズ",
  "releaseDate": "1992/1/31",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "352",
  "ini": "さ",
  "name": "サイバードッジ",
  "maker": "トンキンハウス",
  "releaseDate": "1992/1/31",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "353",
  "ini": "し",
  "name": "CD-ROMマガジン ウルトラボックス6号",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/1/31",
  "price": "5800",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "354",
  "ini": "ふ",
  "name": "ブロウニング",
  "maker": "日本テレネット",
  "releaseDate": "1992/2/7",
  "price": "6980",
  "genre": "アクション・シューティング",
  "icNo": "5"
 },
 {
  "id": "355",
  "ini": "は",
  "name": "パロディウスだ！神話からお笑いへ",
  "maker": "コナミ",
  "releaseDate": "1992/2/21",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "356",
  "ini": "あ",
  "name": "IQ PANIC",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1992/2/21",
  "price": "8900",
  "genre": "クイズ",
  "icNo": "4"
 },
 {
  "id": "357",
  "ini": "け",
  "name": "ゲート オブ サンダー",
  "maker": "ハドソン",
  "releaseDate": "1992/2/21",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "358",
  "ini": "て",
  "name": "出たな!! ツインビー",
  "maker": "コナミ",
  "releaseDate": "1992/2/28",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "359",
  "ini": "ま",
  "name": "麻雀覇王伝 カイザーズクエスト",
  "maker": "ユーピーエル",
  "releaseDate": "1992/2/28",
  "price": "7200",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "360",
  "ini": "き",
  "name": "ぎゅわんぶらあ自己中心派 麻雀パズルコレクション",
  "maker": "タイトー",
  "releaseDate": "1992/2/28",
  "price": "6800",
  "genre": "パズル",
  "icNo": "4"
 },
 {
  "id": "361",
  "ini": "ひ",
  "name": "ヒューマンスポーツフェスティバル",
  "maker": "ヒューマン",
  "releaseDate": "1992/2/28",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "362",
  "ini": "か",
  "name": "改造町人シュビビンマン3 異界のプリンセス",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/2/28",
  "price": "6800",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "363",
  "ini": "し",
  "name": "雀偵物語2 宇宙探偵ディバン 出動編",
  "maker": "アトラス",
  "releaseDate": "1992/2/28",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "4"
 },
 {
  "id": "364",
  "ini": "み",
  "name": "未来少年コナン",
  "maker": "日本テレネット",
  "releaseDate": "1992/2/28",
  "price": "7200",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "365",
  "ini": "と",
  "name": "トイレキッズ",
  "maker": "メディアリング",
  "releaseDate": "1992/3/6",
  "price": "6900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "366",
  "ini": "や",
  "name": "山村美紗サスペンス 金盞花京絵皿殺人事件",
  "maker": "ナグザット",
  "releaseDate": "1992/3/6",
  "price": "7200",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "367",
  "ini": "は",
  "name": "パチ夫くん 十番勝負",
  "maker": "ココナッツジャパン",
  "releaseDate": "1992/3/13",
  "price": "7900",
  "genre": "パチンコ",
  "icNo": "1"
 },
 {
  "id": "368",
  "ini": "こ",
  "name": "極楽！中華大仙",
  "maker": "タイトー",
  "releaseDate": "1992/3/13",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "369",
  "ini": "ほ",
  "name": "HAWK F-123",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1992/3/13",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "370",
  "ini": "ら",
  "name": "RISING SUN",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/3/13",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "371",
  "ini": "ま",
  "name": "魔物ハンター妖子 魔界からの転校生",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/3/13",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "372",
  "ini": "さ",
  "name": "サイキック・ストーム",
  "maker": "日本テレネット",
  "releaseDate": "1992/3/19",
  "price": "6980",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "373",
  "ini": "む",
  "name": "夢幻戦士ヴァリス",
  "maker": "日本テレネット",
  "releaseDate": "1992/3/19",
  "price": "6980",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "374",
  "ini": "ま",
  "name": "マインスウィーパー",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1992/3/20",
  "price": "6200",
  "genre": "パズル",
  "icNo": "4"
 },
 {
  "id": "375",
  "ini": "て",
  "name": "天外魔境Ⅱ 卍MARU",
  "maker": "ハドソン",
  "releaseDate": "1992/3/26",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "376",
  "ini": "は",
  "name": "BABEL",
  "maker": "日本テレネット",
  "releaseDate": "1992/3/27",
  "price": "6980",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "377",
  "ini": "く",
  "name": "QUIZ まるごとTheワールド2 タイムマシンにおねがい！",
  "maker": "アトラス",
  "releaseDate": "1992/3/27",
  "price": "6800",
  "genre": "クイズ",
  "icNo": "4"
 },
 {
  "id": "378",
  "ini": "し",
  "name": "シャドー・オブ・ザ・ビースト 魔性の掟",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/3/27",
  "price": "7200",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "379",
  "ini": "ふ",
  "name": "フォゴットンワールド",
  "maker": "NECアベニュー",
  "releaseDate": "1992/3/27",
  "price": "7980",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "380",
  "ini": "か",
  "name": "川のぬし釣り 自然派",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1992/3/27",
  "price": "6900",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "381",
  "ini": "さ",
  "name": "ザ・デビスカップテニス",
  "maker": "マイクロワールド",
  "releaseDate": "1992/4/1",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "382",
  "ini": "ひ",
  "name": "ビルダーランド",
  "maker": "マイクロワールド",
  "releaseDate": "1992/4/1",
  "price": "6800",
  "genre": "アクション・パズル",
  "icNo": "5"
 },
 {
  "id": "383",
  "ini": "す",
  "name": "スーパー雷電",
  "maker": "ハドソン",
  "releaseDate": "1992/4/2",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "384",
  "ini": "ね",
  "name": "熱血高校ドッジボール部 PCサッカー編",
  "maker": "ナグザット",
  "releaseDate": "1992/4/3",
  "price": "6900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "385",
  "ini": "ち",
  "name": "超時空要塞マクロス2036",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/4/3",
  "price": "7200",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "386",
  "ini": "け",
  "name": "源平討魔伝 巻ノ弐",
  "maker": "ナムコ",
  "releaseDate": "1992/4/7",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "387",
  "ini": "す",
  "name": "スターパロジャー",
  "maker": "ハドソン",
  "releaseDate": "1992/4/24",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "388",
  "ini": "し",
  "name": "雀偵物語2 宇宙探偵ディバン 完結編",
  "maker": "アトラス",
  "releaseDate": "1992/4/24",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "4"
 },
 {
  "id": "389",
  "ini": "す",
  "name": "SPRIGGAN markⅡ Re Terraform Project",
  "maker": "ナグザット",
  "releaseDate": "1992/5/1",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "390",
  "ini": "て",
  "name": "テラフォーミング",
  "maker": "ナグザット",
  "releaseDate": "1992/5/1",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "391",
  "ini": "き",
  "name": "CAMPAIGN VERSION 大戦略Ⅱ",
  "maker": "マイクロキャビン",
  "releaseDate": "1992/5/29",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "392",
  "ini": "と",
  "name": "ドラえもん のび太のドラビアンナイト",
  "maker": "ハドソン",
  "releaseDate": "1992/5/29",
  "price": "4800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "393",
  "ini": "あ",
  "name": "アドベンチャークイズ カプコンワールド ハテナの大冒険",
  "maker": "ハドソン",
  "releaseDate": "1992/6/19",
  "price": "6200",
  "genre": "クイズ",
  "icNo": "5"
 },
 {
  "id": "394",
  "ini": "と",
  "name": "トップをねらえ！GunBuster VOL.1",
  "maker": "リバーヒルソフト",
  "releaseDate": "1992/6/25",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "395",
  "ini": "と",
  "name": "ドルアーガの塔",
  "maker": "ナムコ",
  "releaseDate": "1992/6/25",
  "price": "6800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "396",
  "ini": "た",
  "name": "高橋名人の新冒険島",
  "maker": "ハドソン",
  "releaseDate": "1992/6/26",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "397",
  "ini": "え",
  "name": "F1 CIRCUS SPECIAL POLE TO WIN",
  "maker": "日本物産",
  "releaseDate": "1992/6/26",
  "price": "7900",
  "genre": "レース",
  "icNo": "5"
 },
 {
  "id": "398",
  "ini": "し",
  "name": "ジェノサイド",
  "maker": "ブレイングレイ",
  "releaseDate": "1992/6/26",
  "price": "6800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "399",
  "ini": "ら",
  "name": "ライザンバーⅢ",
  "maker": "データウエスト",
  "releaseDate": "1992/6/26",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "400",
  "ini": "そ",
  "name": "ソルジャーブレイド",
  "maker": "ハドソン",
  "releaseDate": "1992/7/10",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "401",
  "ini": "か",
  "name": "カラーウォーズ",
  "maker": "ココナッツ・ジャパン",
  "releaseDate": "1992/7/10",
  "price": "7300",
  "genre": "パズル",
  "icNo": "4"
 },
 {
  "id": "402",
  "ini": "あ",
  "name": "サマーカーニバル'92 アルザディック",
  "maker": "ナグザット",
  "releaseDate": "1992/7/17",
  "price": "2980",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "403",
  "ini": "そ",
  "name": "ソーサリアン",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/7/17",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "404",
  "ini": "ろ",
  "name": "ロードス島戦記",
  "maker": "ハドソン",
  "releaseDate": "1992/7/17",
  "price": "7200",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "405",
  "ini": "た",
  "name": "TATSUJIN",
  "maker": "タイトー",
  "releaseDate": "1992/7/24",
  "price": "7200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "406",
  "ini": "す",
  "name": "ストラテゴ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/7/24",
  "price": "6900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "407",
  "ini": "ほ",
  "name": "ぽっぷ'nまじっく",
  "maker": "日本テレネット",
  "releaseDate": "1992/7/24",
  "price": "6980",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "408",
  "ini": "さ",
  "name": "ザ・キックボクシング",
  "maker": "マイクロワールド",
  "releaseDate": "1992/7/31",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "409",
  "ini": "ほ",
  "name": "ボナンザブラザーズ",
  "maker": "NECアベニュー",
  "releaseDate": "1992/7/31",
  "price": "6800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "410",
  "ini": "は",
  "name": "パワーリーグ5",
  "maker": "ハドソン",
  "releaseDate": "1992/8/7",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "411",
  "ini": "す",
  "name": "スナッチャー パイロットディスク",
  "maker": "コナミ",
  "releaseDate": "1992/8/7",
  "price": "1500",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "412",
  "ini": "と",
  "name": "ドラゴンナイトⅡ",
  "maker": "NECアベニュー",
  "releaseDate": "1992/8/7",
  "price": "7400",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "413",
  "ini": "く",
  "name": "クイズの星",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/8/10",
  "price": "6800",
  "genre": "クイズ",
  "icNo": "5"
 },
 {
  "id": "414",
  "ini": "へ",
  "name": "ベイビー・ジョー ザ・スーパーヒーロー",
  "maker": "マイクロワールド",
  "releaseDate": "1992/8/28",
  "price": "7200",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "415",
  "ini": "ふ",
  "name": "ファージアスの邪皇帝 NEO METAL FANTASY",
  "maker": "ヒューマン",
  "releaseDate": "1992/8/29",
  "price": "7500",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "416",
  "ini": "と",
  "name": "TRAVEL エプル",
  "maker": "日本テレネット",
  "releaseDate": "1992/9/4",
  "price": "6980",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "417",
  "ini": "え",
  "name": "F-1チームシミュレーション PROJECT F",
  "maker": "日本テレネット",
  "releaseDate": "1992/9/11",
  "price": "6980",
  "genre": "レース・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "418",
  "ini": "せ",
  "name": "ゼロウィング",
  "maker": "ナグザット",
  "releaseDate": "1992/9/18",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "419",
  "ini": "た",
  "name": "ダンジョン・マスター Theron's Quest",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/9/18",
  "price": "8200",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "420",
  "ini": "え",
  "name": "エグザイルⅡ 邪念の事象",
  "maker": "日本テレネット",
  "releaseDate": "1992/9/22",
  "price": "7200",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "421",
  "ini": "ほ",
  "name": "炎の闘球児 ドッジ弾平",
  "maker": "ハドソン",
  "releaseDate": "1992/9/25",
  "price": "6500",
  "genre": "スポーツ・RPG",
  "icNo": "1"
 },
 {
  "id": "422",
  "ini": "る",
  "name": "LOOM",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/9/25",
  "price": "8000",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "423",
  "ini": "う",
  "name": "ウィザードリィⅤ",
  "maker": "ナグザット",
  "releaseDate": "1992/9/25",
  "price": "7400",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "424",
  "ini": "こ",
  "name": "コズミック・ファンタジー3 冒険少年レイ",
  "maker": "日本テレネット",
  "releaseDate": "1992/9/25",
  "price": "7600",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "425",
  "ini": "し",
  "name": "シェイプシフター 魔界英雄伝",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/9/25",
  "price": "7400",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "426",
  "ini": "や",
  "name": "YAWARA！",
  "maker": "ソフィックス",
  "releaseDate": "1992/10/1",
  "price": "6900",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "427",
  "ini": "け",
  "name": "激写ボーイ",
  "maker": "アイレム",
  "releaseDate": "1992/10/2",
  "price": "7000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "428",
  "ini": "す",
  "name": "スターモビール",
  "maker": "ナグザット",
  "releaseDate": "1992/10/2",
  "price": "6800",
  "genre": "パズル",
  "icNo": "4"
 },
 {
  "id": "429",
  "ini": "ら",
  "name": "らんま1/2 打倒、元祖無差別格闘流！",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/10/2",
  "price": "7200",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "430",
  "ini": "さ",
  "name": "ザ・プロ野球 SUPER",
  "maker": "インテック",
  "releaseDate": "1992/10/9",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "431",
  "ini": "す",
  "name": "スライムワールド",
  "maker": "マイクロワールド",
  "releaseDate": "1992/10/9",
  "price": "7200",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "432",
  "ini": "は",
  "name": "パワースポーツ",
  "maker": "ハドソン",
  "releaseDate": "1992/10/10",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "433",
  "ini": "く",
  "name": "クイズ 殿様の野望",
  "maker": "ハドソン",
  "releaseDate": "1992/10/10",
  "price": "6200",
  "genre": "クイズ",
  "icNo": "4"
 },
 {
  "id": "434",
  "ini": "き",
  "name": "キアイダン00",
  "maker": "日本テレネット",
  "releaseDate": "1992/10/23",
  "price": "6980",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "435",
  "ini": "す",
  "name": "スナッチャー",
  "maker": "コナミ",
  "releaseDate": "1992/10/23",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "436",
  "ini": "き",
  "name": "銀河お嬢様伝説ユナ",
  "maker": "ハドソン",
  "releaseDate": "1992/10/23",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "437",
  "ini": "ふ",
  "name": "ファイヤープロレスリング3 Legend Bout",
  "maker": "ヒューマン",
  "releaseDate": "1992/11/13",
  "price": "7900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "438",
  "ini": "ひ",
  "name": "PC原人シリーズ PC電人",
  "maker": "ハドソン",
  "releaseDate": "1992/11/20",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "439",
  "ini": "さ",
  "name": "サイキック・ディテクティヴ・シリーズ Vol.3 アヤ",
  "maker": "データウエスト",
  "releaseDate": "1992/11/20",
  "price": "7600",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "440",
  "ini": "よ",
  "name": "横山光輝 真・三国志 天下は我に",
  "maker": "ナグザット",
  "releaseDate": "1992/11/20",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "4"
 },
 {
  "id": "441",
  "ini": "て",
  "name": "テラクレスタⅡ マンドラーの逆襲",
  "maker": "日本物産",
  "releaseDate": "1992/11/27",
  "price": "6900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "442",
  "ini": "こ",
  "name": "ゴッドパニック 至上最強軍団",
  "maker": "テイチク",
  "releaseDate": "1992/11/27",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "443",
  "ini": "れ",
  "name": "レミングス",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/11/27",
  "price": "7200",
  "genre": "パズル",
  "icNo": "5"
 },
 {
  "id": "444",
  "ini": "も",
  "name": "桃太郎伝説外伝 第1集",
  "maker": "ハドソン",
  "releaseDate": "1992/12/4",
  "price": "6500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "445",
  "ini": "て",
  "name": "TECMO ワールドカップ スーパーサッカー",
  "maker": "メディアリング",
  "releaseDate": "1992/12/4",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "446",
  "ini": "す",
  "name": "スーパーシュヴァルツシルト2",
  "maker": "工画堂スタジオ",
  "releaseDate": "1992/12/4",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "447",
  "ini": "ち",
  "name": "超時空要塞マクロス 永遠のラヴソング",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/12/4",
  "price": "7400",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "448",
  "ini": "ほ",
  "name": "ボンバーマン'93",
  "maker": "ハドソン",
  "releaseDate": "1992/12/11",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "449",
  "ini": "た",
  "name": "ダウンタウン熱血行進曲 それゆけ大運動会",
  "maker": "ナグザット",
  "releaseDate": "1992/12/11",
  "price": "7200",
  "genre": "スポーツ・アクション",
  "icNo": "5"
 },
 {
  "id": "450",
  "ini": "ね",
  "name": "ネクスザール",
  "maker": "ナグザット",
  "releaseDate": "1992/12/11",
  "price": "7400",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "451",
  "ini": "え",
  "name": "F1サーカス'92",
  "maker": "日本物産",
  "releaseDate": "1992/12/18",
  "price": "7400",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "452",
  "ini": "い",
  "name": "イメージファイト2 OPERATION DEEPSTRIKER",
  "maker": "アイレム",
  "releaseDate": "1992/12/18",
  "price": "7700",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "453",
  "ini": "く",
  "name": "グラディウスⅡ GOFERの野望",
  "maker": "コナミ",
  "releaseDate": "1992/12/18",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "454",
  "ini": "す",
  "name": "Superリアル麻雀Special ミキ・カスミ・ショウコの思い出より",
  "maker": "ナグザット",
  "releaseDate": "1992/12/18",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "455",
  "ini": "は",
  "name": "パステルLime",
  "maker": "ナグザット",
  "releaseDate": "1992/12/18",
  "price": "7200",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "456",
  "ini": "ふ",
  "name": "ブライⅡ 闇皇帝の逆襲",
  "maker": "リバーヒルソフト",
  "releaseDate": "1992/12/18",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "457",
  "ini": "も",
  "name": "モトローダー MC",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/12/18",
  "price": "7200",
  "genre": "レース",
  "icNo": "5"
 },
 {
  "id": "458",
  "ini": "し",
  "name": "上海Ⅲ ドラゴンズアイ バトル上海",
  "maker": "アスク講談社",
  "releaseDate": "1992/12/18",
  "price": "7500",
  "genre": "パズル",
  "icNo": "4"
 },
 {
  "id": "459",
  "ini": "は",
  "name": "パチ夫くん 笑う宇宙",
  "maker": "ココナッツ・ジャパン",
  "releaseDate": "1992/12/22",
  "price": "7900",
  "genre": "パチンコ",
  "icNo": "5"
 },
 {
  "id": "460",
  "ini": "う",
  "name": "宇宙戦艦ヤマト",
  "maker": "ヒューマン",
  "releaseDate": "1992/12/22",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "461",
  "ini": "と",
  "name": "ドラゴンスレイヤー 英雄伝説Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1992/12/23",
  "price": "7200",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "462",
  "ini": "け",
  "name": "GAIN GROUND SX",
  "maker": "NECアベニュー",
  "releaseDate": "1992/12/25",
  "price": "7200",
  "genre": "アクション・シューティング",
  "icNo": "5"
 },
 {
  "id": "463",
  "ini": "さ",
  "name": "サークⅠ・Ⅱ",
  "maker": "日本テレネット",
  "releaseDate": "1992/12/25",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "464",
  "ini": "い",
  "name": "井上麻美 この星にたったひとりのキミ",
  "maker": "ハドソン",
  "releaseDate": "1992/12/25",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "465",
  "ini": "ち",
  "name": "超兄貴",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/12/25",
  "price": "7200",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "466",
  "ini": "す",
  "name": "スーパー麻雀大会",
  "maker": "光栄",
  "releaseDate": "1992/12/28",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "467",
  "ini": "ま",
  "name": "魔物ハンター妖子 遠き呼び声",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1993/1/8",
  "price": "7200",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "468",
  "ini": "し",
  "name": "シムアース The Living Planet",
  "maker": "ハドソン",
  "releaseDate": "1993/1/14",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "469",
  "ini": "め",
  "name": "メタモジュピター",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1993/1/22",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "470",
  "ini": "ふ",
  "name": "ふしぎの海のナディア",
  "maker": "ハドソン",
  "releaseDate": "1993/1/29",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "471",
  "ini": "は",
  "name": "バトルロードランナー",
  "maker": "ハドソン",
  "releaseDate": "1993/2/10",
  "price": "5800",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "472",
  "ini": "こ",
  "name": "コズミック・ファンタジー ビジュアル集",
  "maker": "日本テレネット",
  "releaseDate": "1993/2/12",
  "price": "4980",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "473",
  "ini": "こ",
  "name": "コットン",
  "maker": "ハドソン",
  "releaseDate": "1993/2/12",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "474",
  "ini": "つ",
  "name": "つっぱり大相撲 平成版",
  "maker": "ナグザット",
  "releaseDate": "1993/2/19",
  "price": "6300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "475",
  "ini": "う",
  "name": "ヴァリス ビジュアル集",
  "maker": "日本テレネット",
  "releaseDate": "1993/2/19",
  "price": "4980",
  "genre": "その他",
  "icNo": "4"
 },
 {
  "id": "476",
  "ini": "く",
  "name": "クレスト オブ ウルフ",
  "maker": "ハドソン",
  "releaseDate": "1993/2/26",
  "price": "6800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "477",
  "ini": "ほ",
  "name": "ホラーストーリー",
  "maker": "NECアベニュー",
  "releaseDate": "1993/2/26",
  "price": "7200",
  "genre": "アクション・シューティング",
  "icNo": "5"
 },
 {
  "id": "478",
  "ini": "お",
  "name": "ポリス・コネクション",
  "maker": "日本テレネット",
  "releaseDate": "1993/2/26",
  "price": "7600",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "479",
  "ini": "け",
  "name": "幻蒼大陸オーレリア",
  "maker": "タイトー",
  "releaseDate": "1993/2/26",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "4"
 },
 {
  "id": "480",
  "ini": "の",
  "name": "信長の野望 武将風雲録",
  "maker": "光栄",
  "releaseDate": "1993/2/27",
  "price": "13800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "481",
  "ini": "せ",
  "name": "ゼロヨンチャンプⅡ",
  "maker": "メディアリング",
  "releaseDate": "1993/3/5",
  "price": "7800",
  "genre": "レース",
  "icNo": "5"
 },
 {
  "id": "482",
  "ini": "た",
  "name": "双截龍Ⅱ The Revenge",
  "maker": "ナグザット",
  "releaseDate": "1993/3/12",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "483",
  "ini": "し",
  "name": "CD BATTLE 光の勇者たち",
  "maker": "キングレコード",
  "releaseDate": "1993/3/19",
  "price": "6300",
  "genre": "その他",
  "icNo": "5"
 },
 {
  "id": "484",
  "ini": "し",
  "name": "ジム・パワー",
  "maker": "マイクロワールド",
  "releaseDate": "1993/3/19",
  "price": "6800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "485",
  "ini": "た",
  "name": "ダンジョン エクスプローラーⅡ",
  "maker": "ハドソン",
  "releaseDate": "1993/3/26",
  "price": "6800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "486",
  "ini": "と",
  "name": "トップをねらえ！ GunBuster VOL.2",
  "maker": "リバーヒルソフト",
  "releaseDate": "1993/3/26",
  "price": "7200",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "487",
  "ini": "ふ",
  "name": "フォーセット アムール",
  "maker": "ナグザット",
  "releaseDate": "1993/3/26",
  "price": "7600",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "488",
  "ini": "む",
  "name": "ムーンライトレディ",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1993/3/26",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "489",
  "ini": "え",
  "name": "英雄三国志",
  "maker": "アイレム",
  "releaseDate": "1993/3/26",
  "price": "7700",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "490",
  "ini": "て",
  "name": "天使の詩Ⅱ 堕天使の選択",
  "maker": "日本テレネット",
  "releaseDate": "1993/3/26",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "491",
  "ini": "ら",
  "name": "ラプラスの魔",
  "maker": "ヒューマン",
  "releaseDate": "1993/3/30",
  "price": "7500",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "492",
  "ini": "こ",
  "name": "CALⅡ",
  "maker": "NECアベニュー",
  "releaseDate": "1993/3/31",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "493",
  "ini": "ひ",
  "name": "PC原人3",
  "maker": "ハドソン",
  "releaseDate": "1993/4/2",
  "price": "7200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "494",
  "ini": "ふ",
  "name": "フィーンドハンター",
  "maker": "ライトスタッフ",
  "releaseDate": "1993/4/16",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "495",
  "ini": "う",
  "name": "ウィンズ オブ サンダー",
  "maker": "ハドソン",
  "releaseDate": "1993/4/23",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "496",
  "ini": "し",
  "name": "雀偵物語3 セイバーエンジェル",
  "maker": "アトラス",
  "releaseDate": "1993/4/23",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "497",
  "ini": "て",
  "name": "TVスポーツ・アイスホッケー",
  "maker": "ビクター音楽産業",
  "releaseDate": "1993/4/29",
  "price": "7200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "498",
  "ini": "て",
  "name": "TVスポーツ・バスケットボール",
  "maker": "ビクター音楽産業",
  "releaseDate": "1993/4/29",
  "price": "7200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "499",
  "ini": "し",
  "name": "Jリーグ グレイテストイレブン",
  "maker": "日本物産",
  "releaseDate": "1993/5/14",
  "price": "7400",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "500",
  "ini": "く",
  "name": "クイズキャラバン カルトQ",
  "maker": "ハドソン",
  "releaseDate": "1993/5/28",
  "price": "5800",
  "genre": "クイズ",
  "icNo": "4"
 },
 {
  "id": "501",
  "ini": "し",
  "name": "シャーロック・ホームズの探偵講座Ⅱ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1993/5/28",
  "price": "8200",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "502",
  "ini": "え",
  "name": "A列車で行こうⅢ",
  "maker": "アートディンク",
  "releaseDate": "1993/6/11",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "503",
  "ini": "す",
  "name": "ストリートファイターⅡダッシュ",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1993/6/12",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "504",
  "ini": "は",
  "name": "パワーテニス",
  "maker": "ハドソン",
  "releaseDate": "1993/6/25",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "505",
  "ini": "れ",
  "name": "レインボーアイランド",
  "maker": "NECアベニュー",
  "releaseDate": "1993/6/30",
  "price": "7600",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "506",
  "ini": "て",
  "name": "天外魔境 風雲カブキ伝",
  "maker": "ハドソン",
  "releaseDate": "1993/7/10",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "507",
  "ini": "い",
  "name": "1552 天下大乱",
  "maker": "アスク講談社",
  "releaseDate": "1993/7/16",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "508",
  "ini": "う",
  "name": "ウィザードリィⅠ・Ⅱ",
  "maker": "ナグザット",
  "releaseDate": "1993/7/23",
  "price": "8400",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "509",
  "ini": "さ",
  "name": "サマーカーニバル'93 ネクスザールスペシャル",
  "maker": "ナグザット",
  "releaseDate": "1993/7/23",
  "price": "4980",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "510",
  "ini": "ふ",
  "name": "ブラックホールアサルト",
  "maker": "マイクロネット",
  "releaseDate": "1993/7/23",
  "price": "6800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "511",
  "ini": "み",
  "name": "ミスティック フォーミュラ",
  "maker": "マイクロキャビン",
  "releaseDate": "1993/7/23",
  "price": "7800",
  "genre": "アクション・シューティング",
  "icNo": "5"
 },
 {
  "id": "512",
  "ini": "ひ",
  "name": "PC原人シリーズ CD電人 ロカビリー天国",
  "maker": "ハドソン",
  "releaseDate": "1993/7/30",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "513",
  "ini": "は",
  "name": "原由子 眠れぬ夜の小さなお話",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1993/7/30",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "514",
  "ini": "そ",
  "name": "卒業 Graduation",
  "maker": "NECアベニュー",
  "releaseDate": "1993/7/30",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "515",
  "ini": "さ",
  "name": "サイキック・ディテクティヴ・シリーズ Vol.4 オルゴール",
  "maker": "データウエスト",
  "releaseDate": "1993/8/6",
  "price": "7600",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "516",
  "ini": "ち",
  "name": "チャンピオンシップ・ラリー",
  "maker": "インテック",
  "releaseDate": "1993/8/6",
  "price": "7800",
  "genre": "レース",
  "icNo": "5"
 },
 {
  "id": "517",
  "ini": "ら",
  "name": "ラングリッサー 光輝の末裔",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1993/8/6",
  "price": "7900",
  "genre": "シミュレーション・RPG",
  "icNo": "5"
 },
 {
  "id": "518",
  "ini": "め",
  "name": "メタルエンジェル",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1993/9/24",
  "price": "7800",
  "genre": "育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "519",
  "ini": "ま",
  "name": "麻雀クリニック SPECIAL",
  "maker": "ナグザット",
  "releaseDate": "1993/9/24",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "520",
  "ini": "き",
  "name": "機動警察パトレイバー グリフォン篇",
  "maker": "リバーヒルソフト",
  "releaseDate": "1993/9/30",
  "price": "7200",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "521",
  "ini": "あ",
  "name": "蒼き狼と白き牝鹿 元朝秘史",
  "maker": "光栄",
  "releaseDate": "1993/9/30",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "522",
  "ini": "ま",
  "name": "麻雀オンザビーチ",
  "maker": "NECアベニュー",
  "releaseDate": "1993/9/30",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "523",
  "ini": "ゆ",
  "name": "幽☆遊☆白書 闇勝負!! 暗黒武闘術会",
  "maker": "バンプレスト",
  "releaseDate": "1993/9/30",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "524",
  "ini": "さ",
  "name": "三國志Ⅲ",
  "maker": "光栄",
  "releaseDate": "1993/10/1",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "525",
  "ini": "は",
  "name": "パワーリーグ'93",
  "maker": "ハドソン",
  "releaseDate": "1993/10/15",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "526",
  "ini": "し",
  "name": "シルフィア",
  "maker": "トンキンハウス",
  "releaseDate": "1993/10/22",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "527",
  "ini": "す",
  "name": "スタートリング オデッセイ",
  "maker": "レイ・フォース",
  "releaseDate": "1993/10/22",
  "price": "8200",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "528",
  "ini": "き",
  "name": "GALAXY刑事 GAYVAN",
  "maker": "インテック",
  "releaseDate": "1993/10/29",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "529",
  "ini": "ま",
  "name": "Might and Magic Ⅲ",
  "maker": "ハドソン",
  "releaseDate": "1993/10/29",
  "price": "6800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "530",
  "ini": "ま",
  "name": "マジクール",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1993/10/29",
  "price": "6800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "531",
  "ini": "あ",
  "name": "悪魔城ドラキュラX 血の輪廻",
  "maker": "コナミ",
  "releaseDate": "1993/10/29",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "532",
  "ini": "そ",
  "name": "ソードマスター",
  "maker": "ライトスタッフ",
  "releaseDate": "1993/11/19",
  "price": "8300",
  "genre": "シミュレーション・RPG",
  "icNo": "5"
 },
 {
  "id": "533",
  "ini": "る",
  "name": "ルイン 神の遺産",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1993/11/19",
  "price": "8800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "534",
  "ini": "く",
  "name": "クイズ DE 学園祭",
  "maker": "ナグザット",
  "releaseDate": "1993/11/26",
  "price": "8800",
  "genre": "クイズ",
  "icNo": "5"
 },
 {
  "id": "535",
  "ini": "た",
  "name": "ただいま勇者募集中",
  "maker": "ヒューマン",
  "releaseDate": "1993/11/26",
  "price": "7900",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "536",
  "ini": "ふ",
  "name": "フェイスボール",
  "maker": "リバーヒルソフト",
  "releaseDate": "1993/11/26",
  "price": "7200",
  "genre": "アクション・3D",
  "icNo": "5"
 },
 {
  "id": "537",
  "ini": "き",
  "name": "機装ルーガ",
  "maker": "工画堂スタジオ",
  "releaseDate": "1993/12/3",
  "price": "8800",
  "genre": "シミュレーション・RPG",
  "icNo": "5"
 },
 {
  "id": "538",
  "ini": "ほ",
  "name": "ボンバーマン'94",
  "maker": "ハドソン",
  "releaseDate": "1993/12/10",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "539",
  "ini": "お",
  "name": "オーロラクエスト おたくの星座 in Another World",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1993/12/10",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "540",
  "ini": "い",
  "name": "伊賀忍伝 凱王",
  "maker": "日本物産",
  "releaseDate": "1993/12/10",
  "price": "7900",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "541",
  "ini": "ひ",
  "name": "秘密の花園",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1993/12/10",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "542",
  "ini": "の",
  "name": "信長の野望 全国版",
  "maker": "光栄",
  "releaseDate": "1993/12/11",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "543",
  "ini": "す",
  "name": "スーパーリアル麻雀PⅣカスタム",
  "maker": "ナグザット",
  "releaseDate": "1993/12/17",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "544",
  "ini": "ま",
  "name": "マーシャル・チャンピオン",
  "maker": "コナミ",
  "releaseDate": "1993/12/17",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "545",
  "ini": "や",
  "name": "闇の血族 遥かなる記憶",
  "maker": "ナグザット",
  "releaseDate": "1993/12/17",
  "price": "9800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "546",
  "ini": "ふ",
  "name": "フラッシュハイダース",
  "maker": "ライトスタッフ",
  "releaseDate": "1993/12/19",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "547",
  "ini": "い",
  "name": "イースⅣ The Dawn of Y's",
  "maker": "ハドソン",
  "releaseDate": "1993/12/22",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "548",
  "ini": "す",
  "name": "スーパーダライアスⅡ",
  "maker": "NECアベニュー",
  "releaseDate": "1993/12/24",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "549",
  "ini": "せ",
  "name": "セクシーアイドル麻雀",
  "maker": "日本物産",
  "releaseDate": "1993/12/24",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "550",
  "ini": "た",
  "name": "ダウンタウン熱血物語",
  "maker": "ナグザット",
  "releaseDate": "1993/12/24",
  "price": "8800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "551",
  "ini": "し",
  "name": "真・女神転生",
  "maker": "アトラス",
  "releaseDate": "1993/12/25",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "552",
  "ini": "は",
  "name": "爆笑吉本新喜劇 今日はこれぐらいにしといたる！",
  "maker": "ハドソン",
  "releaseDate": "1994/1/3",
  "price": "6800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "553",
  "ini": "そ",
  "name": "ソル：モナージュ",
  "maker": "アイレム",
  "releaseDate": "1994/1/7",
  "price": "7700",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "554",
  "ini": "ふ",
  "name": "フォーメーションサッカー オン Jリーグ",
  "maker": "ヒューマン",
  "releaseDate": "1994/1/15",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "555",
  "ini": "え",
  "name": "エメラルドドラゴン",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/1/28",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "556",
  "ini": "か",
  "name": "格闘覇王伝説アルガノス",
  "maker": "インテック",
  "releaseDate": "1994/1/28",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "557",
  "ini": "す",
  "name": "スターブレイカー",
  "maker": "レイ・フォース",
  "releaseDate": "1994/2/10",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "558",
  "ini": "か",
  "name": "風の伝説ザナドゥ",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/2/18",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "559",
  "ini": "は",
  "name": "パラディオン AUTO CRUSHER PALLADIUM",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/2/25",
  "price": "8800",
  "genre": "シミュレーション・バトル",
  "icNo": "5"
 },
 {
  "id": "560",
  "ini": "ま",
  "name": "マージャン レモンエンジェル",
  "maker": "ナグザット",
  "releaseDate": "1994/2/25",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "561",
  "ini": "こ",
  "name": "ゴジラ 爆闘烈伝",
  "maker": "東宝",
  "releaseDate": "1994/2/26",
  "price": "8200",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "562",
  "ini": "し",
  "name": "THE ATLAS Renaissance Voyager",
  "maker": "アートディンク",
  "releaseDate": "1994/3/4",
  "price": "9800",
  "genre": "探索・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "563",
  "ini": "う",
  "name": "ウィザードリィⅢ・Ⅳ",
  "maker": "ナグザット",
  "releaseDate": "1994/3/4",
  "price": "8400",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "564",
  "ini": "は",
  "name": "パワーゴルフ2 GOLFER",
  "maker": "ハドソン",
  "releaseDate": "1994/3/4",
  "price": "6800",
  "genre": "スポーツ・3D・スロット",
  "icNo": "5"
 },
 {
  "id": "565",
  "ini": "か",
  "name": "餓狼伝説2 新たなる闘い",
  "maker": "ハドソン",
  "releaseDate": "1994/3/12",
  "price": "6900",
  "genre": "対戦格闘",
  "icNo": "3"
 },
 {
  "id": "566",
  "ini": "こ",
  "name": "CALⅢ 完結編",
  "maker": "NECアベニュー",
  "releaseDate": "1994/3/25",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "567",
  "ini": "ふ",
  "name": "プリンセス・ミネルバ",
  "maker": "リバーヒルソフト",
  "releaseDate": "1994/3/25",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "568",
  "ini": "り",
  "name": "龍虎の拳",
  "maker": "ハドソン",
  "releaseDate": "1994/3/26",
  "price": "6900",
  "genre": "対戦格闘",
  "icNo": "3"
 },
 {
  "id": "569",
  "ini": "ふ",
  "name": "フレイCD サーク外伝",
  "maker": "マイクロキャビン",
  "releaseDate": "1994/3/30",
  "price": "8600",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "570",
  "ini": "も",
  "name": "モンスターメーカー 闇の竜騎士",
  "maker": "NECアベニュー",
  "releaseDate": "1994/3/30",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "571",
  "ini": "は",
  "name": "パチ夫くん3 パチスロ&パチンコ",
  "maker": "ココナッツ・ジャパン",
  "releaseDate": "1994/4/15",
  "price": "8800",
  "genre": "パチンコ・スロット",
  "icNo": "5"
 },
 {
  "id": "572",
  "ini": "ふ",
  "name": "ぷよぷよCD",
  "maker": "NECアベニュー",
  "releaseDate": "1994/4/22",
  "price": "5600",
  "genre": "パズル",
  "icNo": "5"
 },
 {
  "id": "573",
  "ini": "は",
  "name": "爆伝アンバランスゾーン",
  "maker": "ソニー・ミュージックエンタテインメント",
  "releaseDate": "1994/4/22",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "574",
  "ini": "は",
  "name": "初恋物語",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1994/4/28",
  "price": "7800",
  "genre": "恋愛・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "575",
  "ini": "か",
  "name": "風霧",
  "maker": "ナグザット",
  "releaseDate": "1994/4/28",
  "price": "8600",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "576",
  "ini": "ち",
  "name": "超英雄伝説ダイナスティックヒーロー",
  "maker": "ハドソン",
  "releaseDate": "1994/5/20",
  "price": "6800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "577",
  "ini": "と",
  "name": "ときめきメモリアル",
  "maker": "コナミ",
  "releaseDate": "1994/5/27",
  "price": "8800",
  "genre": "恋愛・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "578",
  "ini": "わ",
  "name": "ワールドヒーローズ2",
  "maker": "ハドソン",
  "releaseDate": "1994/6/4",
  "price": "6900",
  "genre": "対戦格闘",
  "icNo": "3"
 },
 {
  "id": "579",
  "ini": "こ",
  "name": "コズミック・ファンタジー4 銀河少年伝説 突入編",
  "maker": "日本テレネット",
  "releaseDate": "1994/6/10",
  "price": "7600",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "580",
  "ini": "こ",
  "name": "KO世紀 ビースト三獣士 ガイア復活 完結編",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/6/17",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "581",
  "ini": "さ",
  "name": "ザ・プロ野球 SUPER'94",
  "maker": "インテック",
  "releaseDate": "1994/6/17",
  "price": "7200",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "582",
  "ini": "ふ",
  "name": "ブランディッシュ",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/6/17",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "583",
  "ini": "て",
  "name": "天地を喰らう",
  "maker": "NECアベニュー",
  "releaseDate": "1994/6/17",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "584",
  "ini": "さ",
  "name": "3×3EYES 三只眼變成",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/7/8",
  "price": "8800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "585",
  "ini": "は",
  "name": "バスティール2",
  "maker": "ヒューマン",
  "releaseDate": "1994/7/8",
  "price": "8400",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "586",
  "ini": "ち",
  "name": "チキチキボーイズ",
  "maker": "NECアベニュー",
  "releaseDate": "1994/7/15",
  "price": "4800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "587",
  "ini": "え",
  "name": "栄冠は君に 高校野球全国大会",
  "maker": "アートディンク",
  "releaseDate": "1994/7/15",
  "price": "9800",
  "genre": "スポーツ・育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "588",
  "ini": "あ",
  "name": "アドヴァンスト ヴァリアブル・ジオ",
  "maker": "TGL",
  "releaseDate": "1994/7/22",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "589",
  "ini": "と",
  "name": "ドラゴンナイトⅢ",
  "maker": "NECアベニュー",
  "releaseDate": "1994/7/22",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "590",
  "ini": "こ",
  "name": "GS美神",
  "maker": "バンプレスト",
  "releaseDate": "1994/7/29",
  "price": "8800",
  "genre": "カードバトル・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "591",
  "ini": "ね",
  "name": "ネオ・ネクタリス",
  "maker": "ハドソン",
  "releaseDate": "1994/7/29",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "592",
  "ini": "せ",
  "name": "聖戦士伝承 雀卓の騎士",
  "maker": "日本物産",
  "releaseDate": "1994/8/1",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "593",
  "ini": "す",
  "name": "SUPERリアル麻雀PⅡ・Ⅲカスタム",
  "maker": "ナグザット",
  "releaseDate": "1994/8/5",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "594",
  "ini": "ひ",
  "name": "美少女戦士セーラームーン",
  "maker": "バンプレスト",
  "releaseDate": "1994/8/5",
  "price": "8800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "595",
  "ini": "ほ",
  "name": "ぽっぷるメイル",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/8/12",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "596",
  "ini": "あ",
  "name": "アルシャーク",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/8/26",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "597",
  "ini": "ま",
  "name": "マッドストーカー FULL METAL FORCE",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/9/15",
  "price": "5800",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "598",
  "ini": "せ",
  "name": "セクシーアイドルまーじゃん ファッション物語",
  "maker": "日本物産",
  "releaseDate": "1994/9/16",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "599",
  "ini": "す",
  "name": "ストライダー飛竜",
  "maker": "NECアベニュー",
  "releaseDate": "1994/9/22",
  "price": "6000",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "600",
  "ini": "た",
  "name": "誕生 Debut",
  "maker": "NECアベニュー",
  "releaseDate": "1994/9/22",
  "price": "7800",
  "genre": "育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "601",
  "ini": "や",
  "name": "YAWARA！2",
  "maker": "ソフィックス",
  "releaseDate": "1994/9/23",
  "price": "7900",
  "genre": "アドベンチャー・ミニゲーム・クイズ",
  "icNo": "5"
 },
 {
  "id": "602",
  "ini": "さ",
  "name": "サークⅢ The eternal recurrence",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/9/30",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "603",
  "ini": "と",
  "name": "ドラゴンハーフ",
  "maker": "マイクロキャビン",
  "releaseDate": "1994/9/30",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "604",
  "ini": "め",
  "name": "女神天国",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/9/30",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "605",
  "ini": "す",
  "name": "スタートリング・オデッセイⅡ 魔竜戦争",
  "maker": "レイ・フォース",
  "releaseDate": "1994/10/21",
  "price": "9600",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "606",
  "ini": "は",
  "name": "バステッド",
  "maker": "NECアベニュー",
  "releaseDate": "1994/10/21",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "607",
  "ini": "ふ",
  "name": "ブラッド・ギア",
  "maker": "ハドソン",
  "releaseDate": "1994/10/28",
  "price": "6800",
  "genre": "アクション・RPG",
  "icNo": "5"
 },
 {
  "id": "608",
  "ini": "そ",
  "name": "卒業写真 美姫",
  "maker": "ココナッツ・ジャパン",
  "releaseDate": "1994/10/28",
  "price": "8980",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "609",
  "ini": "は",
  "name": "ハイパーウォーズ",
  "maker": "ハドソン",
  "releaseDate": "1994/11/5",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "610",
  "ini": "と",
  "name": "ドラゴンボールZ 偉大なる孫悟空伝説",
  "maker": "バンダイ",
  "releaseDate": "1994/11/11",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "611",
  "ini": "て",
  "name": "電脳天使 デジタルアンジュ",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1994/11/16",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "612",
  "ini": "く",
  "name": "クイズアベニューⅢ",
  "maker": "NECアベニュー",
  "releaseDate": "1994/11/25",
  "price": "6800",
  "genre": "クイズ",
  "icNo": "5"
 },
 {
  "id": "613",
  "ini": "け",
  "name": "ゲッツェンディーナー",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/11/25",
  "price": "7800",
  "genre": "アクション・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "614",
  "ini": "こ",
  "name": "コズミック・ファンタジー4 銀河少年伝説 激闘編",
  "maker": "日本テレネット",
  "releaseDate": "1994/11/25",
  "price": "7600",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "615",
  "ini": "し",
  "name": "新日本プロレスリング'94 バトルフィールドin闘強導夢",
  "maker": "フジコム",
  "releaseDate": "1994/11/25",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "616",
  "ini": "ひ",
  "name": "美少女戦士セーラームーン collection",
  "maker": "バンプレスト",
  "releaseDate": "1994/11/25",
  "price": "8800",
  "genre": "ミニゲーム",
  "icNo": "5"
 },
 {
  "id": "617",
  "ini": "か",
  "name": "餓狼伝説 SPECIAL",
  "maker": "ハドソン",
  "releaseDate": "1994/12/2",
  "price": "6900",
  "genre": "対戦格闘",
  "icNo": "3"
 },
 {
  "id": "618",
  "ini": "か",
  "name": "カードエンジェルス",
  "maker": "フジコム",
  "releaseDate": "1994/12/9",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "619",
  "ini": "あ",
  "name": "\"秋山仁の数学ミステリー 秘宝\"\"インドの炎\"\"を死守せよ！\"",
  "maker": "NHKソフトウェア",
  "releaseDate": "1994/12/10",
  "price": "8000",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "620",
  "ini": "ふ",
  "name": "藤子・F・不二雄の21エモン めざせ！ホテル王",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/12/16",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "621",
  "ini": "ろ",
  "name": "ロードス島戦記Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1994/12/16",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "622",
  "ini": "あ",
  "name": "アルナムの牙 獣族十二神徒伝説",
  "maker": "ライトスタッフ",
  "releaseDate": "1994/12/22",
  "price": "8300",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "623",
  "ini": "ほ",
  "name": "ボンバーマン ぱにっくボンバー",
  "maker": "ハドソン",
  "releaseDate": "1994/12/22",
  "price": "6800",
  "genre": "パズル",
  "icNo": "5"
 },
 {
  "id": "624",
  "ini": "し",
  "name": "Jリーグ トリメンダスサッカー'94",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1994/12/23",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "625",
  "ini": "そ",
  "name": "卒業Ⅱ Neo Generation",
  "maker": "リバーヒルソフト",
  "releaseDate": "1994/12/23",
  "price": "8800",
  "genre": "育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "626",
  "ini": "と",
  "name": "とらべらーず！ 伝説をぶっとばせ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/12/29",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "627",
  "ini": "ふ",
  "name": "プリンセスメーカー1",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1995/1/3",
  "price": "8800",
  "genre": "育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "628",
  "ini": "め",
  "name": "メタルエンジェル2",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1995/1/20",
  "price": "8900",
  "genre": "育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "629",
  "ini": "せ",
  "name": "セクシーアイドル麻雀 野球拳の詩",
  "maker": "日本物産",
  "releaseDate": "1995/1/31",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "630",
  "ini": "ふ",
  "name": "ファイプロ女子 憧夢超女大戦 全女vsJWP",
  "maker": "ヒューマン",
  "releaseDate": "1995/2/3",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "631",
  "ini": "か",
  "name": "カブキ一刀涼談",
  "maker": "ハドソン",
  "releaseDate": "1995/2/24",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "3"
 },
 {
  "id": "632",
  "ini": "あ",
  "name": "愛・超兄貴",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1995/2/24",
  "price": "8900",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "633",
  "ini": "あ",
  "name": "姐）",
  "maker": "NECアベニュー",
  "releaseDate": "1995/2/24",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "634",
  "ini": "し",
  "name": "雀神伝説 QUEST OF JONGMASTER",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1995/2/24",
  "price": "6900",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "635",
  "ini": "す",
  "name": "スーパーリアル麻雀P.Ⅴカスタム",
  "maker": "ナグザット",
  "releaseDate": "1995/3/3",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "636",
  "ini": "そ",
  "name": "ソリッドフォース",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1995/3/17",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "637",
  "ini": "と",
  "name": "ドラゴンナイト&グラフィティ",
  "maker": "NECアベニュー",
  "releaseDate": "1995/3/31",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "638",
  "ini": "ふ",
  "name": "フォーメーションサッカー95 デッラセリエA",
  "maker": "ヒューマン",
  "releaseDate": "1995/4/7",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "639",
  "ini": "す",
  "name": "スロット勝負師",
  "maker": "日本物産",
  "releaseDate": "1995/4/28",
  "price": "8500",
  "genre": "スロット",
  "icNo": "5"
 },
 {
  "id": "640",
  "ini": "れ",
  "name": "レッスルエンジェルス DOUBLE IMPACT 団体経営編&新人デビュー編",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1995/5/19",
  "price": "7800",
  "genre": "スポーツ・育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "641",
  "ini": "き",
  "name": "機装ルーガⅡ The Ends of Shangrila",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1995/5/26",
  "price": "8800",
  "genre": "シミュレーション・RPG",
  "icNo": "5"
 },
 {
  "id": "642",
  "ini": "く",
  "name": "空想科学世界ガリバーボーイ",
  "maker": "ハドソン",
  "releaseDate": "1995/5/26",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "643",
  "ini": "て",
  "name": "天地無用！魎皇鬼",
  "maker": "NECアベニュー",
  "releaseDate": "1995/5/26",
  "price": "8200",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "644",
  "ini": "ふ",
  "name": "プリンセスメーカー2",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1995/6/16",
  "price": "9800",
  "genre": "育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "645",
  "ini": "き",
  "name": "銀河お嬢様伝説ユナ（再販版）",
  "maker": "ハドソン",
  "releaseDate": "1995/6/16",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "646",
  "ini": "ね",
  "name": "熱血レジェンド ベースボーラー",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1995/6/16",
  "price": "8800",
  "genre": "スポーツ・RPG",
  "icNo": "5"
 },
 {
  "id": "647",
  "ini": "れ",
  "name": "レニーブラスター",
  "maker": "NECアベニュー",
  "releaseDate": "1995/6/23",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "648",
  "ini": "き",
  "name": "銀河お嬢様伝説ユナ2 永遠のプリンセス",
  "maker": "ハドソン",
  "releaseDate": "1995/6/30",
  "price": "7800",
  "genre": "カードバトル・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "649",
  "ini": "か",
  "name": "風の伝説ザナドゥⅡ",
  "maker": "日本ファルコム",
  "releaseDate": "1995/6/30",
  "price": "9800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "650",
  "ini": "あ",
  "name": "あすか120%マキシマ BURNING Fest.",
  "maker": "NECアベニュー",
  "releaseDate": "1995/7/28",
  "price": "8200",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "651",
  "ini": "す",
  "name": "スペースインベーダー The Orisinal Game",
  "maker": "NECアベニュー",
  "releaseDate": "1995/7/28",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "652",
  "ini": "ひ",
  "name": "百物語 ほんとにあった怖い話",
  "maker": "ハドソン",
  "releaseDate": "1995/8/4",
  "price": "6800",
  "genre": "サウンドノベル",
  "icNo": "5"
 },
 {
  "id": "653",
  "ini": "ふ",
  "name": "プライベート・アイ・ドル",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1995/8/11",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "654",
  "ini": "ま",
  "name": "マージャン・ソード プリンセス・クエスト外伝",
  "maker": "ナグザット",
  "releaseDate": "1995/8/11",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "655",
  "ini": "し",
  "name": "真怨霊戦記",
  "maker": "フジコム",
  "releaseDate": "1995/9/22",
  "price": "8500",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "656",
  "ini": "さ",
  "name": "ザ・ティーヴィーショー",
  "maker": "ライトスタッフ",
  "releaseDate": "1995/9/29",
  "price": "6800",
  "genre": "アクション・パズル",
  "icNo": "5"
 },
 {
  "id": "657",
  "ini": "り",
  "name": "リンダキューブ",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1995/10/13",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "658",
  "ini": "し",
  "name": "将棋データベース 棋友",
  "maker": "セタ",
  "releaseDate": "1995/10/27",
  "price": "6900",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "659",
  "ini": "と",
  "name": "同級生",
  "maker": "NECアベニュー",
  "releaseDate": "1995/11/23",
  "price": "8800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "660",
  "ini": "き",
  "name": "銀河婦警伝説サファイア",
  "maker": "ハドソン",
  "releaseDate": "1995/11/24",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "3"
 },
 {
  "id": "661",
  "ini": "せ",
  "name": "聖夜物語 ANEARTH FANTASY STORIES",
  "maker": "ハドソン",
  "releaseDate": "1995/12/22",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "662",
  "ini": "す",
  "name": "スチーム・ハーツ",
  "maker": "テイジイエル販売",
  "releaseDate": "1996/3/22",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "663",
  "ini": "ふ",
  "name": "ぷよぷよCD通",
  "maker": "NECインターチャネル",
  "releaseDate": "1996/3/29",
  "price": "7800",
  "genre": "パズル",
  "icNo": "5"
 },
 {
  "id": "664",
  "ini": "う",
  "name": "ヴァージン・ドリーム",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1996/5/31",
  "price": "8800",
  "genre": "育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "665",
  "ini": "こ",
  "name": "GO！GO！バーディーチャンス",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1996/6/28",
  "price": "7800",
  "genre": "スポーツ・育成・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "666",
  "ini": "て",
  "name": "DE・JA",
  "maker": "NECインターチャネル",
  "releaseDate": "1996/7/12",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "667",
  "ini": "は",
  "name": "バザールでござーるのゲームでござーる",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1996/7/26",
  "price": "7800",
  "genre": "パズル",
  "icNo": "5"
 },
 {
  "id": "668",
  "ini": "ま",
  "name": "魔導物語Ⅰ 炎の卒園児",
  "maker": "NECアベニュー",
  "releaseDate": "1996/12/13",
  "price": "7800",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "669",
  "ini": "は",
  "name": "はたらく☆少女 てきぱきワーキン・ラブ",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1997/3/28",
  "price": "7800",
  "genre": "育成・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "670",
  "ini": "て",
  "name": "デッド・オブ・ザ・ブレイン1&2",
  "maker": "NECホームエレクトロニクス",
  "releaseDate": "1999/6/3",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 }
];